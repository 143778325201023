<template>
    <!-- 
        Модальне вікно предперегляду детальної інформації до сповіщення
    -->
    
    <modal
        :title="this.lang" 
        :stylebody="'var(--vz-body-bg)'"
        :sizeModal="this.sizeFullModal"
        :key="this.componentKey"
        @close="$emit('close')"
    > 
        <!-- Слот шапки модального вікна (кнопки по праву сторону шапки) -->
        <template v-slot:head-bottom>
            <!-- Робимо вікно на увесь екран -->
            <button 
                @click="expandBlock"
                type="button" 
                class="btn btn-ghost-secondary btn-icon"
            >
                <i :class="`bx bx-${isFullscreen == false ? 'fullscreen': 'exit-fullscreen'} fs-22`"></i>
            </button>
        </template>
        <template v-slot:body>
            <b-row>
                <b-col lg="12">
                    <b-card>
                        <!-- Вміст повідомлення у форматі Quill Editor -->
                        <div class="ql-container">
                            <div class="ql-editor" v-html="form"></div>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal/modal-lg' // компонент модального вікна

export default {
    props: ['data', 'lang'],
    components: {
        modal
    },
    data(){
        return{
            form:'',
            isFullscreen: false, // прапорець стану розміру екрану
            sizeFullModal: false, // прапорець стану розміру екрану (для пропсу)
            componentKey: 0, // зміна для ререндеру
        }
    },
    created(){
        this.form = this.data
    },
    methods: {
        expandBlock(){
            this.sizeFullModal = !this.sizeFullModal;
            this.isFullscreen = !this.isFullscreen;
            this.componentKey++
        }
    }
}
</script>

<style>
.ql-editor {
    max-height: max-content!important;
}
</style>