<template>
    <!-- Модальне вікно предперегляду повідомлення -->

    <dialogModal> 
        <template v-slot:title>{{ $t('CompanyFound') }}</template>
        <template v-slot:body>
            <div  class="notificationsItem" >
                <div  class="notificationsItem__img" >
                    <template v-if="type == 'alert'">
                        <svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" fill="#6c757d" viewBox="0 0 512 512">
                            <path
                                d="M256,0C114.613,0,0,114.617,0,256c0,141.391,114.613,256,256,256s256-114.609,256-256C512,114.617,397.387,0,256,0z
                                M256,128c17.674,0,32,14.328,32,32c0,17.68-14.326,32-32,32s-32-14.32-32-32C224,142.328,238.326,128,256,128z M304,384h-96
                                c-8.836,0-16-7.156-16-16c0-8.836,7.164-16,16-16h16v-96h-16c-8.836,0-16-7.156-16-16c0-8.836,7.164-16,16-16h64
                                c8.836,0,16,7.164,16,16v112h16c8.836,0,16,7.164,16,16C320,376.844,312.836,384,304,384z"
                            ></path>
                        </svg>
                    </template>
                    <template v-if="type == 'update'">
                        <svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" fill="#6c757d" viewBox="0 0 512 512">
                            <path
                                d="m466.412 411.413h-6.957c34.076-44.417 52.545-98.491 52.545-155.413 0-68.38-26.629-132.667-74.98-181.02-48.353-48.351-112.64-74.98-181.02-74.98-34.554 0-68.081 6.771-99.651 20.124-7.63 3.227-11.198 12.029-7.972 19.658 3.228 7.63 12.024 11.198 19.659 7.972 27.852-11.781 57.447-17.754 87.964-17.754 124.617 0 226 101.383 226 226 0 46.957-14.228 91.724-40.639 129.386-.53-7.803-7.01-13.973-14.948-13.973-8.284 0-15 6.716-15 15v40c0 8.284 6.716 15 15 15h39.999c8.284 0 15-6.716 15-15s-6.716-15-15-15z"
                            ></path>
                            <path
                                d="m343.964 464.246c-27.852 11.781-57.447 17.754-87.964 17.754-124.617 0-226-101.383-226-226 0-46.95 14.236-91.704 40.64-129.362.542 7.792 7.018 13.949 14.947 13.949 8.284 0 15-6.716 15-15v-40c0-8.284-6.716-15-15-15h-40c-8.284 0-15 6.716-15 15s6.716 15 15 15h6.987c-34.086 44.417-52.574 98.486-52.574 155.413 0 68.38 26.629 132.667 74.98 181.02 48.353 48.351 112.64 74.98 181.02 74.98 34.554 0 68.081-6.771 99.651-20.124 7.63-3.227 11.198-12.029 7.972-19.658-3.227-7.631-12.026-11.2-19.659-7.972z"
                            ></path>
                            <path
                                d="m388.922 325.282c4.083-7.803 7.453-15.917 10.086-24.282h16.992c8.284 0 15-6.716 15-15v-60c0-8.284-6.716-15-15-15h-16.992c-2.633-8.365-6.003-16.479-10.086-24.282l12.035-12.035c5.858-5.858 5.858-15.355 0-21.213l-42.427-42.427c-5.857-5.858-15.355-5.858-21.213 0l-12.035 12.035c-7.802-4.082-15.918-7.454-24.282-10.086v-16.992c0-8.284-6.716-15-15-15h-60c-8.284 0-15 6.716-15 15v16.992c-8.364 2.633-16.48 6.004-24.282 10.086l-12.035-12.035c-2.812-2.813-6.628-4.394-10.606-4.394s-7.794 1.581-10.606 4.394l-42.426 42.427c-5.858 5.858-5.858 15.355 0 21.213l12.034 12.035c-4.083 7.803-7.453 15.917-10.086 24.283h-16.993c-8.284 0-15 6.716-15 15v60c0 8.284 6.716 15 15 15h16.992c2.633 8.366 6.003 16.48 10.086 24.283l-12.034 12.034c-5.858 5.858-5.858 15.355 0 21.213l42.426 42.427c2.812 2.813 6.628 4.394 10.606 4.394s7.794-1.581 10.606-4.394l12.035-12.035c7.802 4.082 15.918 7.453 24.282 10.086v16.991c0 8.284 6.716 15 15 15h60c8.284 0 15-6.716 15-15v-16.992c8.365-2.633 16.479-6.004 24.282-10.086l12.035 12.035c5.857 5.858 15.355 5.858 21.213 0l42.427-42.427c5.858-5.858 5.858-15.355 0-21.213zm-132.922 10.718c-44.112 0-80-35.888-80-80s35.888-80 80-80 80 35.888 80 80-35.888 80-80 80z"
                            ></path>
                        </svg>
                    </template>
                </div>
                <div class="notificationsItem__info" >
                    <div class="notificationsItem__headline" >{{ this.mutateTypeMessage(this.type).name }} </div>
                    <div class="notificationsItem__description" >2024-04-30 14:22:06</div>
                    <div class="notificationsItem__text" v-html="form"></div>
                </div>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <div class="hstack gap-2 mt-3 justify-content-center remove">
                <button class="btn btn-danger" @click="$emit('close')">{{ $t('close') }}</button>
            </div>
        </template>
    </dialogModal>
</template>

<script >
import dialogModal from '@/components/modal/dialog'
import { mutateTypeMessage } from '@/usabilityScripts/globalMutate.js'

export default ({
    props: ['data', 'infoText', 'type'],
    components: {
        dialogModal
    },
    data() {
        return {
            form: ''
        }
    },
    created(){
        this.form = this.data
    },
    methods: {
        mutateTypeMessage(e) { // мутейт типу повідомлення
            return mutateTypeMessage(e)
        },
    }
})
</script>

<style>

.notificationsItem__img {
    width: 50px;
    height: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 5px 10px 0 0;
    /* background: #fff */
}

.notificationsItem {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 15px
}

.notificationsItem:last-child {
    padding-bottom: 5px
}

.notificationsItem:not(:last-child) {
    border-bottom: 1px solid #ddd
}

.notificationsItem:first-child {
    padding-top: 5px
}

.notificationsItem__info {
    width: calc(100% - 60px)
}

.notificationsItem__description {
    color: #85827e;
    font-size: 11px
}

.notificationsItem__headline {
    font-size: 14px;
    color: #2f3136
}

.notificationsItem__buttonsWrap,.notificationsItem__headline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.notificationsItem__buttonsWrap {
    margin-top: 10px
}

.notificationsItem__button {
    width: 50%;
    border-radius: 5px;
    font-size: 12px;
    color: #fff;
    text-align: center;
    padding: 5px;
    cursor: pointer
}

.notificationsItem__button.dialog__button_save {
    margin-right: 5px
}

.notificationsItem__button.decline {
    background: #6c757d;
    margin-left: 5px
}

.notificationsItem__button.decline:hover {
    background: #5b6269
}

.notificationsItem__button.decline.fullWidth {
    width: 100%;
    margin: 0
}

.notificationsItem__text {
    font-size: 12px;
    padding-top: 5px;
    margin-top: 5px;
    border-top: 1px solid #ddd;
    color: #000
}
</style>