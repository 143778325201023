<template>
    <!-- 
        Список повідомлень
        - дозволяє переглянути список повідомлень
        - створити картку повідомлення
        - та інше :)
    -->

    <!-- Компонент хедеру розділу -->
    <PageHeader :title="title" />

    <!-- 
        Компонент таблиці
        - :dataParams - параметри до кнопки фільтру
        - :objParams - параметри для фільтрації таблиці 
        - @search - подія, яка спрацьовує при пошуку у таблиці
        - :columns - конфігурація стовпців таблиці
        - :rows - масив з даними рядків таблиці
        - :pages - об'єкт із даними про пагінацію таблиці
        - :showpag - параметр, який вказує, чи відображати пагінацію на таблиці
        - :createdform - параметр-прапорець, що дозволяє чи забороняє створення нових записів у таблиці
        - :removeitem - параметр-прапорець, що дозволяє чи забораняє видалення записів з таблиці
        - :tabs - параметр-об'єкт із конфігурацією вкладок таблиці
        - :addtabs - параметр-прапорець, що вказує, чи відображати кнопку для додавання вкладок
        - @addCategory - подія, що спрацьовує при додаванні нової категорії
        - @removeCategory - подія, що спрацьовує при видаленні категорії
        - @remove - подія, яка спрацьовує при видаленні запису з таблиці
        - @create - подія, яка спрацьовує при створенні нового співробітника
        - @open - подія, яка спрацьовує при відкритті картки співробітника
        - @getdata - подія, яка спрацьовує при отриманні даних для таблиці
        - @changelimit - подія, яка спрацьовує при зміні ліміту записів на сторінці
        - @changeFilter - подія, яка спрацьовує при зміні фільтрів таблиці
        - @clearFilter - подія, яка спрацьовує при очищенні фільтрів таблиці
        - @changeTab - подія, яка спрацьовує при зміні вкладки таблиці
    -->
    <tablecustom
        v-if="!this.tab"
        :dataParams="dataParams"
        :objParams="objParams"
        @search="searchB" 
        :columns="columns" 
        :rows="rows"
        :pages="objPages"
        :showpag="true"
        :createdform="this.perms[1901] || this.perms[2000]" 
        :tabs="othertabs" 
        @create="showModalAddNew = true" 
        @open="open" 
        @getdata="getdata"
        @changelimit="changelimit"
        @changeFilter="changeFilter" 
        @clearFilter="clearFilter" 
        @changeTab="changeTab"
    />
    <!-- 
        Компонент таблиці
        - :dataParams - параметри до кнопки фільтру
        - :objParams - параметри для фільтрації таблиці 
        - @search - подія, яка спрацьовує при пошуку у таблиці
        - :columns - конфігурація стовпців таблиці
        - :rows - масив з даними рядків таблиці
        - :pages - об'єкт із даними про пагінацію таблиці
        - :showpag - параметр, який вказує, чи відображати пагінацію на таблиці
        - :createdform - параметр-прапорець, що дозволяє чи забороняє створення нових записів у таблиці
        - :removeitem - параметр-прапорець, що дозволяє чи забораняє видалення записів з таблиці
        - :tabs - параметр-об'єкт із конфігурацією вкладок таблиці
        - :addtabs - параметр-прапорець, що вказує, чи відображати кнопку для додавання вкладок
        - @addCategory - подія, що спрацьовує при додаванні нової категорії
        - @removeCategory - подія, що спрацьовує при видаленні категорії
        - @remove - подія, яка спрацьовує при видаленні запису з таблиці
        - @create - подія, яка спрацьовує при створенні нового співробітника
        - @open - подія, яка спрацьовує при відкритті картки співробітника
        - @getdata - подія, яка спрацьовує при отриманні даних для таблиці
        - @changelimit - подія, яка спрацьовує при зміні ліміту записів на сторінці
        - @changeFilter - подія, яка спрацьовує при зміні фільтрів таблиці
        - @clearFilter - подія, яка спрацьовує при очищенні фільтрів таблиці
        - @changeTab - подія, яка спрацьовує при зміні вкладки таблиці
    -->
    <tablecustom
        v-if="this.tab"
        :dataParams="dataParams"
        :objParams="objParams"
        @search="searchB" 
        :columns="tabColumns" 
        :rows="rows"
        :pages="objPages"
        :showpag="true"
        :createdform="this.perms[1901] || this.perms[2000]" 
        :tabs="othertabs" 
        @create="showModalAddNew = true" 
        @open="openFromAnother" 
        @getdata="getdata"
        @changelimit="changelimit"
        @changeFilter="changeFilter" 
        @clearFilter="clearFilter" 
        @changeTab="changeTab"
    />

    <!-- 
        Виклик вікна створення картки повідомлення
        - для відкриття застосовується перевірка значення showModalAddNew
        - @close - подія, яка спрацьовує при закритті картки
        - :editForm - форма на едіт 
        - @getdata - подія оновлення загальних даних
    -->
    <createBox 
        v-if="showModalAddNew == true"
        :editForm="editForm" 
        @close="closeModal"
        @getdata="getdata"
    ></createBox>

    <!-- Вікно з переглядом даних по повідомленню/сповіщенню 
        - для відкриття застосовується перевірка значення showMessage
        - :obj - передача об'єкту для перегляду
        - @close - подія закриття модального вікна
        - @edit - подія редагування
        - @getdata - подія оновлення даних
        - :release - параметр буде відображати чи повідомлення знаходиться в релізі
        - @getdataInRelease - подія, що допоможе оновити дані і отримати месседжі в релізі
    -->
    <viewcard
        v-if="this.showMessage == true"
        :obj="this.objCard"
        @close="this.showMessage = false; this.inRelease = false"
        @edit="this.editForm = this.objCard; this.showModalAddNew = true"
        @getdata="getdata"
        :release = "this.inRelease"
        @getdataInRelease="getdata('inRelease')"
    />

</template>

<script>
import PageHeader from "@/components/page-header"; // хедер розділу
import tablecustom from '@/components/globaltable/index'; // компонент таблиці
import viewcard from './view/index'; // картка повідомлення
import createBox from './create/create-message.vue' // модальне вікно створення повідомлення
import { MessagesInSky } from '@/API.js'; // клас для взаємодії з API 
import { storeS } from "@/store"; // глобальне сховище даних
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм
import { mutateTypeMessage, mutateViewMessage, mutateRecipientMessage, mutateStatusMessage } from '@/usabilityScripts/globalMutate.js'
import { Messages } from '@/API_Dash.js' // клас для взаємодії з API 

let apiMessagesSky = new MessagesInSky()
let apiServe = new Messages()

export default {
    components: {
        PageHeader,
        tablecustom,
        viewcard,
        createBox
    },
    data() {
        return{
            othertabs: [ // масив з табами
                {
                    checked: true,
                    color: "#1ABC9C",
                    obj: "inRelease",
                    tab: "",
                    title: this.$t("release"),
                    value: ''
                }
            ],
            editForm:'',
            title: this.$t('Messages'), // Заголовок
            showModalAddNew: false, // Прапорець відображення вікна для додавання повідомлення
            showMessage: false, // Прапорець відображення вікна для перегляду повідомлення
            objCard: {}, // дані вибранного повідомлення
            rows: [], // масив шаблонів повідомлення
            columns: [ // масив з колонками
                {
                    name: "id",
                    text: "id",
                    align: "right",
                    status: false,
                },
                {
                    name: this.$t("Point_name"),
                    text: "msgTitle",
                    align: "right",
                    status: true,
                    mutate: (item) => item ? item : "-",
                },
                {
                    name: this.$t("type"),
                    text: "type",
                    align: "right",
                    status: true,
                    mutate: (item) => this.mutateTypeMessage(item).name,
                    mutateClass: (item) => this.mutateTypeMessage(item).color
                },
                {
                    name: this.$t("view"),
                    text: "view",
                    align: "right",
                    status: true,
                    mutate: (item) => this.mutateViewMessage(item)
                },
                {
                    name: this.$t("recipient"),
                    text: "recipient",
                    align: "right",
                    status: true,
                    mutate: (item) => this.mutateRecipientMessage(item)
                },
                {
                    name: this.$t("Message"),
                    text: "shortText",
                    align: "right",
                    status: true,
                },
                {
                    name: this.$t("Status"),
                    text: "status",
                    align: "right",
                    status: true,
                    mutate: (item) => this.mutateStatusMessage(item).name,
                    mutateClass: (item) => this.mutateStatusMessage(item).color
                },
                {
                    name: this.$t("date"),
                    text: "datetime",
                    align: "right",
                    status: false,
                },
                {
                    name: this.$t("MessageType"),
                    text: "typeMsg",
                    align: "right",
                    status: false,
                    mutate: (item) => item ? this.$t(item) : "-"
                },
            ], // колоонки таблиці
            tabColumns: [ // колонки таблиці (в релізі)
                {
                    name: "id",
                    text: "messageId",
                    align: "right",
                    status: false,
                },
                // {
                //     name: this.$t("type"),
                //     text: "type",
                //     align: "right",
                //     status: true,
                //     mutate: (item) => this.mutateTypeMessage(item)
                // },
                {
                    name: this.$t("view"),
                    text: "view",
                    align: "right",
                    status: true,
                    mutate: (item) => this.mutateViewMessage(item)
                },
                {
                    name: this.$t("recipient"),
                    text: "messageViewv",
                    align: "right",
                    status: true,
                    mutate: (item) => this.mutateRecipientMessage(item)
                },
                {
                    name: this.$t("Message"),
                    text: "messageText",
                    align: "right",
                    status: true,
                },
            ],
            objPages: {
                total: 1
            },
            tab: '', // для пересмикання таблиць
            showMessageFromAnother: false,
            inRelease: false
        }
    },
    created() {
        this.getdata()
        this.othertabs['tab'] = 'inRelease'
    },
    methods: {
        changeTab(e, obj) { // змінюємо таб
            console.log(e, obj)

            if(e == 'inRelease') {
                this.getdata('inRelease')
            }
        },
        mutateStatusMessage(e){ // мутейт для статусу повідомлення
            return mutateStatusMessage(e)
        },
        mutateTypeMessage(e) { // мутейт типу повідомленння
            return mutateTypeMessage(e)
        },
        mutateViewMessage(e) { // мутейт того, хто це повідомлення бачить
            return mutateViewMessage(e)
        },
        mutateRecipientMessage(e) { // мутейт отримувача повідомлення
           return mutateRecipientMessage(e)
        },
        getdata(a) {
            if(a) {
                this.tab = a
                // Отримання повідомлень в релізі  
                apiServe.getActiveMessages().then(result => {
                    if(result.status == 'done') {
                        console.log(result)
                        this.rows = result.data.messages
                    } else {
                        this.$toast.error(this.$t('error'))

                        var errorText = 'Помилка отримання активних повідомлень'; 
                        var statusRequest = result.status;
                        var api = 'getActiveMessages';
                        var fileRequest = 'src/views/messages/index.vue';
                        var params = '';
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error));
                    }
                })
            } else {
                this.tab = ''
                // Отримання повідомлень   
                apiMessagesSky.getMessages().then(result => {
                    if(result.status == 'done') {
                        console.log(result)
                        this.rows = result.data.items
                    } else {
                        this.$toast.error(this.$t('error'))

                        var errorText = 'Помилка отримання повідомлень'; 
                        var statusRequest = result.status;
                        var api = 'getMessages';
                        var fileRequest = 'src/views/messages/index.vue';
                        var params = '';
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error));
                    }
                })
            }
        },
        closeModal(){
            /*
                Функція для закриття модального вікна.
                Встановлює прапорці showModal та showModalAddNew в false, та викликає функцію getdata для оновлення даних таблиці.
            */

            // this.showModal = false;
            this.showModalAddNew = false;
            this.editForm = ''
            // this.getdata(this.objParams.page);
        },
        open(e) {
            // відкриття картки повідомлення
            apiMessagesSky.getMessage(e.id).then(result => {
                if(result.status == 'done') {
                    this.objCard = result.data
                    this.showMessage = true
                } else {
                    this.$toast.error(this.$t('error'))

                    var errorText = 'Помилка відкриття повідомлення'; 
                    var statusRequest = result.status;
                    var api = 'getMessage';
                    var fileRequest = 'src/views/messages/index.vue';
                    var params = e.id;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        openFromAnother(e) {
            // відкриття картки повідомлення
            apiServe.getActiveMessage(e.messageId).then(result => {
                if(result.status == 'done') {
                    this.objCard = result.data
                    this.inRelease = true
                } else {
                    this.$toast.error(this.$t('error'))

                    var errorText = 'Помилка відкриття активного повідомлення'; 
                    var statusRequest = result.status;
                    var api = 'getActiveMessage';
                    var fileRequest = 'src/views/messages/index.vue';
                    var params = e.messageId;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        }
    },
    
    computed: {
        user() {
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        },
        perms() {
            /*
                Властивість, яка повертає права доступу користувача зі збережених даних у глобальному стору.
            */
            return storeS.perms
        }
    }
}
</script>