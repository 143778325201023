<template>
    <!-- Модальне вікно створення картки повідомлення -->
    <modal >
        <template v-slot:title>{{ $t('NewMessage') }}</template>

        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <!-- рядок, де пишемо текст
                Доступно тільки, якщо це не режим редагування
            -->
            <b-row v-if="!this.obj.more_langs['uk']">
                <div class="message">
                    <h4 class="fs-20" style="color: black; font-weight: 400;">{{ $t('Message') }}</h4>
                    <hr>
                    <div class="d-flex" style="display: grid; grid-template-columns: 3 8 1;">
                        <div  class="flex-grow-1">
                            <select class="form-select" aria-label="Default select example" v-model="pickedLang">
                                <option v-for="items, i in Object.keys(this.form.more_langs)" :value="items" :key="i">{{ this.availableLangs.find(el => items == el.value).title }}</option>
                            </select>
                        </div>
                        <div>
                            <button class="btn btn-success" style="margin: 0 5px" @click="saveLang(this.pickedLang)"><i class='bx bx-save' ></i></button>
                        </div>
                    </div>
                    <div class="row" style="margin: 10px 0">
                        
                        <!--пишемо опис -->
                        <QuillEditor 
                            ref="quill" 
                            v-model:content="text" 
                            :modules="modules" 
                            theme="snow" 
                            contentType="html" 
                            toolbar="full"
                            @textChange="input(text)" 
                        />
                    </div>
                </div>
            </b-row>
            <div class="row">
                <!-- Рядок, де ми перебираємо наявні мови (беруться зі списку наявних в коротких повідомленнях) -->
                <h4 class="fs-20" style="color: black; font-weight: 400;">{{ $t('translationLanguages') }}</h4>
                <hr>
                <div class="d-flex" v-for="item, k in Object.keys(this.form.more_langs)" :key="k" style="margin-bottom: 10px; gap: 10px;">
                    <div class="flex-grow-3">
                        <select class="form-select" aria-label="Default select example">
                            <option v-for="items, i in Object.keys(this.form.more_langs)" :value="items.value" :key="i" :selected="item == items">{{ this.availableLangs.find(el => items == el.value).title }}</option>
                        </select>
                    </div>
                    <div class="flex-grow-1">
                        <input type="text" class="form-control" :value="this.form.more_langs[item]" @change="this.switchText(k, $event.target.value)">
                    </div>
                    <div class="flex-grow-2">

                        <!-- Виклик додаткового вікна редагування -->
                        <button 
                            class="btn btn-success" 
                            style="margin: 0 5px" 
                            @click="editTextLang(item, this.form.more_langs[item], k)"
                        >
                            <i class='bx bx-edit'></i>
                        </button>

                        <!-- Кнопка копіювання тексту з версткою -->
                        <button 
                            class="btn btn-success" 
                            style="margin: 0 5px" 
                            @click="copy(this.form.more_langs[item])"
                            title="Скопіювати"
                        >
                            <i class='bx bx-copy'></i>
                        </button>

                        <!-- Кнопка предперегляду детальної інформації враховуючи мову -->
                        <button 
                            class="btn btn-info" 
                            style="margin: 0 5px" 
                            @click="previewShow(item, k, 'short')"
                            title="Предперегляд"
                        >
                            <i class='bx bxs-show' ></i>
                        </button>

                        <!-- <button class="btn btn-success" :disabled="item == 'uk'" style="margin: 0 5px" @click="translateOneKey(k)"><i class='bx bx-globe'></i></button> -->
                        <!-- <button class="btn btn-success" style="margin: 0 5px" @click="saveLang(k, this.form.more_langs[item])"><i class='bx bx-save' ></i></button> -->
                    </div>
                </div>
                <div class="row" style="margin-top: 20px;">
                    <div class="col-md-6">
                        <button class="btn btn-success" @click="translateAll">
                            {{this.$t('translateAll')}}  
                        </button>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:footer-bottom>

            <!-- Кнопки додавання повідомлення -->
            <button 
                class="btn btn-success" 
                v-if="!this.editForm && this.perms[1901]" 
                @click="addInfo"
            >
                {{ this.$t('Add') }}
            </button>

            <!-- Кнопки редагування повідомлення -->
            <button 
                class="btn btn-success" 
                v-if="this.editForm && this.perms[1902]" 
                @click="editInfo"
            >
                {{ this.$t('Edit') }}
            </button>

        </template>
    </modal>

    <!-- Вікно предперегляду повідомлення (довге) -->
    <previewMoreMsg 
      v-if="this.previewBox.show"
      @close="this.previewBox.show = false"
      :data="this.previewBox.data"
      :lang="this.previewBox.lang"
    />

    <!-- Вікно редагування повідомлення через редактор  -->
    <editText 
        v-if="editBox.show"
        @close="editBox.show = false"
        @saveEditLang="saveEditLang"
        :data="editBox.data"
        :obj="this.form"
        :typeMsg="long"
    />
    
</template>

<script>
import modal from '@/components/modal-medium' 
import BlotFormatter from 'quill-blot-formatter' // Для змінення розміру зображення в QE
import ImageUploader from 'quill-image-uploader'  // Для завантаження зображення на сервер в QE
import previewMoreMsg from '../view/preview/message-desc' 
import editText from '../create/editText'
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'
import { MessagesInSky } from '@/API.js'
import { Messages } from '@/API_Dash.js'
import { copy } from '@/usabilityScripts/copy.js' // Функція копіювання
import { storeS } from '@/store'; // глобальне сховище даних
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логів в телеграм

let apiServe = new Messages()
let apiMessagesSky = new MessagesInSky()

export default {
    components: {
        modal,
        Multiselect,
        previewMoreMsg,
        editText
    },
    props: ['id', 'obj'],
    setup() {
        
        const modules = [
            {
                name: 'imageUploader',
                module: ImageUploader,
                options: {
                    upload: (file) => {
                        console.log("file > ", file);
                            
                        return new Promise((resolve, reject) => {

                            // Конвертація файлу у формат base64
                            const reader = new FileReader();
                            reader.readAsDataURL(file);

                            reader.onload = () => {

                                let base64String = reader.result; // Full base64 string

                                // Ensure base64 string starts with the correct prefix
                                if (!base64String.startsWith('data:image/png;base64,')) {
                                    reject('Invalid image format'); // Reject if format is invalid
                                    return;
                                }

                                let data = JSON.stringify({
                                    "img": base64String
                                })

                                apiMessagesSky.uploadImageInMessage(data).then(result => {
                                    if(result.status == 'done') {
                                        resolve(result.data)
                                    }
                                })
                            };
                            reader.onerror = (error) => {
                                reject('File reading failed');
                                console.error('Error:', error);
                            };
                        })
                    },
                },
            },
            {
                name: "BlotFormatter",
                module: BlotFormatter,
                options: {},
            },
        ];

        return { modules }
    },
    data(){
        return{
            pickedLang: 'uk',
            availableLangs: [
                {
                    "value": 'fr',
                    "title": "Français"
                },
                {
                    "value": "en",
                    "title": "English"
                },
                {
                    "value": "ru",
                    "title": "Русский"
                },
                {
                    "value": "de",
                    "title": "Deutsch"
                },
                {
                    "value": "es",
                    "title": "Español"
                },
                {
                    "value": "pl",
                    "title": "Polski"
                },
                {
                    "value": "uk",
                    "title": "Українська"
                },
                {
                    "value": "it",
                    "title": "Italiano"
                },
                {
                    "value": "he",
                    "title": "עִבְרִית"
                },
                {
                    "value": "tr",
                    "title": "Türkçe"
                },
                {
                    "value": "et",
                    "title": "eesti keel"
                },
                {
                    "value": "be",
                    "title": "Беларуская мова"
                },
                {
                    "value": "ar",
                    "title": "العربية"
                },
                {
                    "value": "fi",
                    "title": "suomi"
                },
                {
                    "value": "lt",
                    "title": "lietuvių kalba"
                },
                {
                    "value": "tg",
                    "title": "Забони тоҷикӣ"
                },
                {
                    "value": "pt",
                    "title": "Português"
                },
                {
                    "value": "ka",
                    "title": "Georgian (Georgia)"
                },
                {
                    "value": "cn",
                    "title": "Chinese Simplified"
                },
                {
                    "value": "el",
                    "title": "Greek"
                },
                {
                    "value": "km",
                    "title": "Khmer (Cambodia)"
                },
                {
                    "value": "lv",
                    "title": "Latvian"
                },
                {
                    "value": "ro",
                    "title": "Romanian"
                },
                {
                    "value": "th",
                    "title": "ภาษาไทย (Thai)"
                }
            ],
            form: {
                "more_langs":{

                }
            },
            text: '',
            previewBox: {
                data: '',
                lang: '',
                show: false,
            },
            editBox: {
                data: '',
                show: false
            },
        }
    },
    methods: {
        // дістаємо мови
        getLangs(){
            console.log(this.obj)
            Object.keys(this.obj.langs).forEach(el => {
                this.form.more_langs[el] = this.obj.more_langs[el]
            })
            console.log(this.langsInPattern)
        },
        saveLang(lang){
            console.log(this.pickedLang)
            this.form.more_langs[lang] = this.text
            console.log('form', this.form)
        },
        addInfo(){
            apiMessagesSky.addInfo(this.id, this.form).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t('done'))
                    this.$emit('updateCard')
                } else {
                    this.$toast.error(this.$t('error'));

                    var errorText = 'Помилка додавання додаткової інформації'; 
                    var statusRequest = result.status;
                    var api = 'addInfo';
                    var fileRequest = 'src/views/messages/create/create-desc.vue';
                    var params = {id: this.id, form: this.form};
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        translateAll() {
            let langArr = []

            Object.keys(this.form.more_langs).forEach(el => {
                if(el != 'uk') {
                    langArr.push(el)
                }
            })

            let obj = {
                text: this.form.more_langs['uk'],
                sourceLang: 'uk',
                targetLangs: langArr
            }
            
            apiServe.translateAllKey(obj).then(result => {
                if(result.status == 'done') {
                    console.log(result);
                    Object.keys(result.data.translates).forEach(el => {
                        this.form.more_langs[el] = result.data.translates[el].text
                    })
                } else {
                    this.$toast.error(this.$t('error'));

                    var errorText = 'Помилка перекладу на всі мови'; 
                    var statusRequest = result.status;
                    var api = 'translateAllKey';
                    var fileRequest = 'src/views/messages/create/create-desc.vue';
                    var params = obj
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        switchText(idx, text) {
            console.log(idx, text)
            this.form.more_langs[Object.keys(this.form.more_langs)[idx]] = text
        },
        editInfo(){
            var moreLangs = {
                "more_langs": this.form.more_langs
            }
            console.log("moreLangs ", moreLangs);
            apiMessagesSky.editInfo(this.editForm.id, moreLangs).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t('alertChanged'))
                    this.$emit('close')
                    this.$emit('updateCard')
                } else {
                    this.$toast.error(this.$t('error'));

                    var errorText = 'Помилка редагування детальної інформації'; 
                    var statusRequest = result.status;
                    var api = 'editInfo';
                    var fileRequest = 'src/views/messages/create/create-desc.vue';
                    var params = {more_langs: moreLangs, id: this.editForm.id}
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        input(text){
            const parser = new DOMParser();
            const doc = parser.parseFromString(text, 'text/html');

            if (doc.body) {
                const elements = doc.body.querySelectorAll('*');

                elements.forEach(element => {
                    const tagName = element.tagName.toLowerCase();

                    switch (tagName) {
                        case 'h1':
                            element.classList.add('notification-title-1');
                        break;
                        case 'h2':
                            element.classList.add('notification-title-2');
                        break;
                        case 'h3':
                            element.classList.add('notification-title-3');
                        break;
                        case 'h4':
                            element.classList.add('notification-title-4');
                        break;
                        case 'h5':
                            element.classList.add('notification-title-5');
                        break;
                        case 'h6':
                            element.classList.add('notification-title-6');
                        break;
                        case 'p':
                            element.classList.add('notification-text');
                        break;
                        case 'ul':
                            element.classList.add('notification-list');
                        break;
                        case 'ol':
                            element.classList.add('notification-ordered-list');
                        break;
                        case 'li':
                            if (element.parentElement.tagName.toLowerCase() === 'ul') {
                                element.classList.add('notification-list__item');
                            } else if (element.parentElement.tagName.toLowerCase() === 'ol') {
                                element.classList.add('notification-ordered-list__item');
                            }
                        break;
                        case 'a':
                            element.classList.add('notification-link');
                        break;
                        case 'img':
                            element.classList.add('notification-img');
                        break;
                        case 'strong':
                            element.classList.add('notification-strong');
                        break;
                        default:
                        break;
                    }
                });

                // Отримуємо оброблений HTML знову
                this.processedHTML = doc.body.innerHTML;
                this.text = doc.body.innerHTML;
            }
        },
        copy(item){
            // Копіювання

            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
        previewShow(item, k){
            // Виклик вікна предперегляду повідомлення

            this.previewBox.data = this.form.more_langs[item];
            this.previewBox.lang = this.availableLangs.find(el => item == el.value).title;
            this.previewBox.show = true
        },
        editTextLang(key, item, k){
            // Редагування повідомлення через візуальний редактор
            
            this.editBox.data = {
                "text": item,
                "lang": this.availableLangs.find(el => key == el.value).title,
                "keyLang": key,
                "k": k
            };
                        
            this.editBox.show = true
        },
        saveEditLang(text, form){
            // Збереження відредагованого повідомлення до мови
            this.form.more_langs[form.keyLang] = text
        },
        fileToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        }
    },
    created(){
        // if(this.obj.more_langs['uk'] != ''){
        //     Object.keys(this.obj.more_langs)
        // } else {
        //     this.getLangs()
        // }
        this.getLangs()
    },
    computed: {
        user() {
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        },
        perms() {
            /*
                Властивість, яка повертає права доступу користувача зі збережених даних у глобальному стору.
            */
            return storeS.perms
        }
    }
}
</script>