<template>
    <!-- Модальне вікно редагування/створення тексту повідомлення (для різних мов) -->

    <modal>
        <!-- Заголовок -->
        <template v-slot:title>{{ this.data.lang }}</template>

        <!-- Слот шапки модального вікна (кнопки по праву сторону шапки) -->
        <template v-slot:head-bottom>
            <button 
                type="button" 
                class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  
                @click="$emit('close')"
            >
                <i class="ri-close-fill align-bottom"></i>
            </button>
        </template>

        <!-- Слот тіло модального вікна -->
        <template v-slot:body>

            <!-- Блок повідомлення -->
            <b-row>
                <div class="message">
                    <!-- Заголовок блоку -->
                    <h4 
                        class="fs-20" 
                        style="color: black; font-weight: 400;"
                    >
                        {{ $t('Message') }}
                    </h4>

                    <hr>

                    <div class="d-flex">
                        <div class="flex-grow-1">
                            <select 
                                class="form-select" 
                                disabled
                            >
                                <option selected>{{ this.data.lang }}</option>
                            </select>
                        </div>

                        <div>
                            
                            <!-- Кнопка предперегляду -->
                            <button 
                                class="btn btn-info" 
                                style="margin: 0 5px" 
                                @click="previewShow(this.form)"
                            >
                                <i class='bx bxs-show'></i>
                            </button>

                            <!-- <button class="btn btn-danger" style="margin: 0 5px; padding: .375rem .75rem"><i style="font-size: 20px;" class='bx bx-x' ></i></button> -->
                        </div>
                    </div>

                    <div class="row" style="margin: 10px 0">
                        <QuillEditor 
                            v-model:content="form.text" 
                            ref="quill" 
                            :modules="modules" 
                            theme="snow" 
                            contentType="html" 
                            toolbar="full"
                            @textChange="input(form.text)" 
                        />
                    </div>
                </div>
            </b-row>
        </template>

        <template v-slot:footer-bottom>

            <!-- Кнопка скасувати редагування повідомлення -->
            <button 
                class="btn btn-danger" 
                @click="$emit('close')"
            >
                {{ this.$t('cancel') }}
            </button>

            <!-- Кнопка зберегти зміни -->
            <button 
                class="btn btn-success" 
                @click="saveEdit()"
            >
                {{ this.$t('save') }}
            </button>

        </template>

    </modal>

    <!-- Вікно предперегляду повідомлення (коротке) -->
    <previewMsg 
        v-if="previewBox.show"
        @close="previewBox.show = false"
        :data="this.previewBox.data"
        :type="this.previewBox.type"
    />

    <!-- Вікно предперегляду повідомлення (довге) -->
    <previewMoreMsg 
        v-if="this.previewBox.showLong"
        @close="this.previewBox.showLong = false"
        :data="this.previewBox.data"
        :lang="this.previewBox.lang"
    />

</template>

<script>
import modal from '@/components/modal-medium' 
import BlotFormatter from 'quill-blot-formatter'
import previewMsg from '../view/preview/message'
import previewMoreMsg from '../view/preview/message-desc' 
import { storeS } from "@/store"; // глобальне сховище даних

export default {
    props: ['data', 'obj', 'typeMsg'],
    components: {
        modal,
        previewMsg,
        previewMoreMsg
    },
    setup: () => {
        const modules = {
            name: 'blotFormatter',  
            module: BlotFormatter, 
            options: {/* options */}
        }
        return { modules }
    },
    data(){
        return {
            form: '',
            processedHTML: '',
            previewBox: {
                data: '',
                type: '',
                show: false,
                showLong: false
            },
        }
    },
    created(){
        this.form = this.data
    },
    methods: {
        saveEdit(){
            // Зберегти відредаговане повідомлення
            this.$emit('saveEditLang', this.processedHTML, this.form)
            this.$emit('close')
        },
        previewShow(item, k){
            // Виклик вікна предперегляду повідомлення
            
            if (this.typeMsg == 'short'){
                this.previewBox.data = item.text;
                this.previewBox.type = this.obj.type;
                this.previewBox.show = true
            } else {
                this.previewBox.data = item.text;
                this.previewBox.lang = item.lang
                this.previewBox.showLong = true
            }
            
        },
        input(text){
            const parser = new DOMParser();
            const doc = parser.parseFromString(text, 'text/html');

            if (doc.body) {
                const elements = doc.body.querySelectorAll('*');

                elements.forEach(element => {
                    const tagName = element.tagName.toLowerCase();

                    switch (tagName) {
                        case 'h1':
                            element.classList.add('notification-title-1');
                        break;
                        case 'h2':
                            element.classList.add('notification-title-2');
                        break;
                        case 'h3':
                            element.classList.add('notification-title-3');
                        break;
                        case 'h4':
                            element.classList.add('notification-title-4');
                        break;
                        case 'h5':
                            element.classList.add('notification-title-5');
                        break;
                        case 'h6':
                            element.classList.add('notification-title-6');
                        break;
                        case 'p':
                            element.classList.add('notification-text');
                        break;
                        case 'ul':
                            element.classList.add('notification-list');
                        break;
                        case 'ol':
                            element.classList.add('notification-ordered-list');
                        break;
                        case 'li':
                            if (element.parentElement.tagName.toLowerCase() === 'ul') {
                                element.classList.add('notification-list__item');
                            } else if (element.parentElement.tagName.toLowerCase() === 'ol') {
                                element.classList.add('notification-ordered-list__item');
                            }
                        break;
                        case 'a':
                            element.classList.add('notification-link');
                        break;
                        case 'img':
                            element.classList.add('notification-img');
                        break;
                        case 'strong':
                            element.classList.add('notification-strong');
                        break;
                        default:
                        break;
                    }
                });

                // Отримуємо оброблений HTML знову
                this.processedHTML = doc.body.innerHTML;
            }
        },
    },
    computed: {
        user() {
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        },
        perms() {
            /*
                Властивість, яка повертає права доступу користувача зі збережених даних у глобальному стору.
            */
            return storeS.perms
        }
    }
}
</script>