<template>
    <!-- Модальне вікно створення картки повідомлення (яке падає в шторку) -->

    <modal>
        <!-- Заголовок -->
        <template v-slot:title>{{ $t('Нове повідомлення') }}</template>

        <!-- Слот шапки модального вікна (кнопки по праву сторону шапки) -->
        <template v-slot:head-bottom>
            <button 
                type="button" 
                class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  
                @click="$emit('close')"
            >
                <i class="ri-close-fill align-bottom"></i>
            </button>
        </template>

        <!-- Слот тіло модального вікна -->
        <template v-slot:body>

            <b-row>

                <!-- назва повідомлення (внутрішня) -->
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('Point_name') }}</h4>
                        <input class="form-control" v-model="form.msgTitle" />
                    </div>
                </b-col>

                <!-- внутрішній тип повідомлення -->
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('type') }}</h4>
                        <b-form-select
                            v-model="form.typeMsg"
                            :options="internalTypes"
                            class="form-control"
                        >
                        </b-form-select>
                    </div>
                </b-col>

            </b-row>

            <hr />

            <b-row>

                <!-- Тип повідомлення -->
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('type') }}</h4>
                        <b-form-select
                            v-model="form.type"
                            :options="types"
                            class="form-control"
                        >
                        </b-form-select>
                    </div>
                </b-col>

                <!-- Права доступу -->
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('perms') }}</h4>
                        <Multiselect 
                            v-model="form.perms"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="false"
                            mode="tags"
                            :options="this.permsList"
                            :placeholder="$t('perms')"
                        />
                    </div>
                </b-col>

                <!-- Видимість -->
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('Visibility') }}</h4>
                        <b-form-select
                            v-model="form.view"
                            :options="listVisibility"
                            class="form-control"
                        >
                        </b-form-select>
                    </div>
                </b-col>

                <!-- Одержувачі -->
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('recipient') }}</h4>
                        <b-form-select
                            v-model="this.recipient"
                            :options="recipients"
                            class="form-control"
                        >
                        </b-form-select>
                    </div>
                </b-col>

                <!-- Одержувачі (по id акаунту) -->
                <b-col lg="6" 
                    v-if="this.recipient == ''"
                >
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('recipient') }}</h4>
                        
                        <!-- Швидки вибір акаунту :) -->
                        <div class="mb-3" v-if="this.user.userid == 34 || this.user.userid == 32 || this.user.userid == 76">
                            <!-- <label class="form-label mb-2">{{ $t('Швидкий вибір акаунту') }}</label> -->
                            <div class="mb-2">
                                <span class="fs-14 badge bg-info copy mb-2" @click="form.recipient = '2bf99ad6-4687-4328-9aad-794894c1102a'" style="margin-right: 5px;">Максу</span>
                                <span class="fs-14 badge bg-info copy mb-2" @click="form.recipient = '172f0645-81a4-4848-8f25-007d60d62fa6'" style="margin-right: 5px;">Максиму</span>
                            </div>
                        </div>

                        <input 
                            type="text" 
                            class="form-control" 
                            v-model="form.recipient" 
                            :placeholder="'id'"
                        />
                    </div>
                </b-col>

                <b-col 
                    lg="6"
                >
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('RegionsMsgs') }}</h4>
                        <Multiselect 
                            v-model="form.countries"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="false"
                            mode="tags"
                            :options="this.countryMsg"
                            :placeholder="$t('RegionsMsgs')"
                            @change="setCountry"
                        />
                    </div>

                </b-col>

            </b-row>

            <!-- Блок повідомлення
                *   Умова: якщо форма редагування відстутня (ми створюємо нове повідомлення)
            -->
            <b-row v-if="this.editForm == ''">
                <div class="message">
                    <!-- Заголовок блоку -->
                    <h4 
                        class="fs-20" 
                        style="color: black; font-weight: 400;"
                    >
                        {{ $t('Message') }}
                    </h4>

                    <hr>

                    <div class="d-flex">
                        <div class="flex-grow-1">
                            <select 
                                class="form-select" 
                                aria-label="Default select example" 
                                disabled
                            >
                                <option value="uk" selected>{{ this.$t('uk_name') }}</option>
                            </select>
                        </div>

                        <div>

                            <!-- Кнопка збереження тексту повідомлення -->
                            <button 
                                class="btn btn-success" 
                                style="margin: 0 5px" 
                                :disabled="this.langsForPattern.find(el => el['uk'])" 
                                @click="this.langsForPattern.push({'uk': form.langs['uk'], edit: false})"
                            >
                                <i class='bx bx-save' ></i>
                            </button>

                            <!-- Кнопка перекладу на інші мови -->
                            <button 
                                class="btn btn-success" 
                                style="margin: 0 5px" 
                                :disabled="this.langsForPattern.find(el => el['en'])" 
                                @click="translateAll(['en', 'ru'], this.form.langs['uk'])"
                            >
                                <i class='bx bx-globe'></i>
                            </button>

                            <!-- Кнопка предперегляду -->
                            <button 
                                class="btn btn-info" 
                                style="margin: 0 5px" 
                                @click="previewShow(this.form.langs['uk'])"
                            >
                                <i class='bx bxs-show'></i>
                            </button>

                            <!-- <button class="btn btn-danger" style="margin: 0 5px; padding: .375rem .75rem"><i style="font-size: 20px;" class='bx bx-x' ></i></button> -->
                        </div>
                    </div>

                    <div class="row" style="margin: 10px 0">
                        <QuillEditor 
                            v-model:content="form.langs['uk']" 
                            ref="quill" 
                            :modules="modules" 
                            theme="snow" 
                            contentType="html" 
                            toolbar="full"
                            @textChange="input(form)" 
                        />
                    </div>
                </div>
            </b-row>

            <!-- Блок "Переклади"
                * Умова: якщо створюємо нове повідомлення
            -->
            <div class="row" v-if="this.editForm == ''">
                <h4 
                    class="fs-20" 
                    style="color: black; font-weight: 400;"
                >
                    {{ $t('translationLanguages') }}
                </h4>

                <hr>

                <div 
                    class="d-flex" 
                    v-for="item, k in this.langsForPattern" 
                    :key="k" 
                    style="margin-bottom: 10px; gap: 10px;"
                >
                    <div class="flex-grow-3">
                        <select 
                            class="form-select" 
                            aria-label="Default select example" 
                            @change="switchLang(k, $event)"
                        >
                            <option 
                                value="" 
                                selected
                            >
                                {{ this.$t("Null") }}</option>
                            <option 
                                v-for="items, i in availableLangs" 
                                :value="items.value" 
                                :key="i" 
                                :selected="item[items.value]"
                            >
                                {{ items.title }}
                            </option>
                        </select>
                    </div>

                    <div class="flex-grow-1">
                        <input 
                            type="text" 
                            class="form-control" 
                            :disabled="this.langsForPattern[k]['edit'] == false" 
                            :value="Object.values(item)[0]" 
                            @change="this.switchText(k, $event.target.value)"
                        >
                    </div>

                    <div class="flex-grow-2">

                        <!-- Кнопка блокування поля повідомлення -->
                        <button 
                            class="btn btn-white-50" 
                            style="margin: 0 5px" 
                            @click="editLang(k)"
                        >
                            <i :class='`${this.langsForPattern[k]["edit"] ? "bx bx-lock-alt" : "bx bx-lock-open-alt"}`'></i>
                        </button>

                        <!-- Виклик додаткового вікна редагування -->
                        <button 
                            class="btn btn-success" 
                            style="margin: 0 5px" 
                            :disabled="this.langsForPattern[k]['edit'] == false"
                            @click="editTextLang(Object.values(item)[0], Object.keys(item)[0], k)"
                        >
                            <i class='bx bx-edit'></i>
                        </button>

                        <!-- Кнопка перекладу -->
                        <button 
                            class="btn btn-success" 
                            style="margin: 0 5px" 
                            :disabled="item.edit == false" 
                            v-if="Object.keys(item)[0] !== 'uk' && Object.keys(item)[0] !== 'en'" 
                            @click="translateOneKey(k, Object.values(item)[0])"
                        >
                            <i class='bx bx-globe'></i>
                        </button>

                        <!-- Кнопка предперегляду -->
                        <button 
                            class="btn btn-info" 
                            style="margin: 0 5px" 
                            @click="previewShow(Object.values(item)[0], k)"
                        >
                            <i class='bx bxs-show'></i>
                        </button>

                        <!-- кнопка видалення  -->
                        <button 
                            class="btn btn-danger" 
                            style="margin: 0 5px; padding: .375rem .75rem" 
                            v-if="Object.keys(item)[0] !== 'uk' && Object.keys(item)[0] !== 'en' && Object.keys(item)[0] !== 'ru'"
                            @click="deleteKey(item, k)"
                        >
                            <i style="font-size: 20px;" class='bx bx-x'></i>
                        </button>
                    </div>
                </div>
                <div class="row" style="margin-top: 20px;">
                    <div class="col-md-6">

                        <!-- Кнопка додавання мови перекладу -->
                        <button 
                            class="btn btn-success" 
                            style="margin-right: 10px;" 
                            @click="addLang"
                        >
                            {{this.$t('AddLang')}}
                        </button>

                        <!-- Кнопка додавання всіх мов перекладу -->
                        <button 
                            class="btn btn-info" 
                            style="margin-right: 10px;" 
                            @click="addAllLang"
                        >
                            {{this.$t('Додати всі мови')}}
                        </button>

                        <!-- Кнопки редагування повідомлення -->
                        <button 
                            class="btn btn-success" 
                            @click="translateAll"
                        >
                            {{this.$t('translateAll')}}  
                        </button>

                    </div>
                </div>
            </div>
            <!-- Блок "Переклади" 
                * Якщо редагуємо повідомлення
            -->
            <div class="row" v-else>
                <h4 
                    class="fs-20" 
                    style="color: black; font-weight: 400;"
                >
                    {{ $t('translationLanguages') }}
                </h4>

                <hr>

                <div 
                    class="d-flex" 
                    v-for="item, k in arrayForEdit" 
                    :key="k" 
                    style="margin-bottom: 10px; gap: 10px;"
                >
                    <div class="flex-grow-3">
                        <select 
                            class="form-select" 
                            aria-label="Default select example" 
                            @change="switchLangInEdit(k, $event)"
                        >
                            <option 
                                v-for="items, i in availableLangs" 
                                :value="items?.value" 
                                :key="i" 
                                :selected="item.lang == items?.value"
                            >
                                {{ items.title ? items.title : this.$t("Null") }}
                            </option>
                        </select>
                    </div>

                    <div class="flex-grow-1">
                        <input 
                            type="text" 
                            class="form-control" 
                            :value="item.text" 
                            :disabled="this.arrayForEdit[k]['edit'] == false"
                            @input="changeInEdit(k, $event.target.value)"
                        >
                    </div>

                    <div class="flex-grow-2">

                        <!-- Кнопка блокування пол`я повідомлення -->
                        <button 
                            class="btn btn-white-50" 
                            style="margin: 0 5px" 
                            @click="this.arrayForEdit[k]['edit'] = !this.arrayForEdit[k]['edit']"
                        >
                            <i :class='`${this.arrayForEdit.find(el => el.lang == item.lang)["edit"] == false ? "bx bx-lock-alt" : "bx bx-lock-open-alt"}`'></i>
                        </button>

                        <!-- Виклик додаткового вікна редагування -->
                        <button 
                            class="btn btn-success" 
                            style="margin: 0 5px" 
                            :disabled="this.arrayForEdit.find(el => el.lang == item.lang).edit == false"
                            @click="editTextLang(this.arrayForEdit[k], this.arrayForEdit[k]['lang'], k)"
                        >
                            <i class='bx bx-edit'></i>
                        </button>

                        <!-- Кнопка перекладу -->
                        <button 
                            class="btn btn-success" 
                            style="margin: 0 5px" 
                            :disabled="this.arrayForEdit.find(el => el.lang == item.lang).edit == false" 
                            v-if="Object.keys(item)[0] !== 'uk' && Object.keys(item)[0] !== 'en'" 
                            @click="translateOneKeyInEdit(k)"
                        >
                            <i class='bx bx-globe'></i>
                        </button>

                        <!-- Кнопка предперегляду -->
                        <button 
                            class="btn btn-info" 
                            style="margin: 0 5px" 
                            @click="previewShow((item.text), k)"
                        >
                            <i class='bx bxs-show'></i>
                        </button>

                        <!-- кнопка видалення  -->
                        <button 
                            class="btn btn-danger" 
                            style="margin: 0 5px; padding: .375rem .75rem" 
                            v-if="this.arrayForEdit[k].lang !== 'uk' && this.arrayForEdit[k].lang !== 'en' && this.arrayForEdit[k].lang !== 'ru'"
                            @click="this.arrayForEdit.splice(k, 1)"
                        >
                            <i style="font-size: 20px;" class='bx bx-x'></i>
                        </button>
                    </div>
                </div>
                <div class="row" style="margin-top: 20px;">
                    <div class="col-md-6">
                        
                        <!-- Кнопка додавання мови перекладу -->
                        <button 
                            class="btn btn-success" 
                            style="margin-right: 10px;" 
                            @click="addLangInEdit"
                        >
                            {{this.$t('AddLang')}}
                        </button>

                        <!-- Кнопка додавання всіх мов перекладу -->
                        <button 
                            class="btn btn-info" 
                            style="margin-right: 10px;" 
                            @click="addAllLangInEdit"
                        >
                            {{this.$t('Додати всі мови')}}
                        </button>

                        <!-- Кнопка для перекладу з основної мови на всі -->
                        <button 
                            class="btn btn-success" 
                            @click="translateAllInEdit"
                        >
                            {{this.$t('translateAll')}}  
                        </button>

                    </div>
                </div>
            </div>
        </template>

        <template v-slot:footer-bottom>

            <!-- Кнопки додавання повідомлення -->
            <button 
                class="btn btn-success" 
                v-if="!this.editForm && this.perms[1901]" 
                @click="addPatern"
            >
                {{ this.$t('Add') }}
            </button>

            <!-- Кнопки редагування повідомлення -->
            <button 
                class="btn btn-success" 
                v-if="this.editForm && this.perms[1902]" 
                @click="editPatern"
            >
                {{ this.$t('Edit') }}
            </button>

        </template>
    </modal>

    <!-- Вікно предперегляду повідомлення (коротке) -->
    <previewMsg 
        v-if="previewBox.show"
        @close="previewBox.show = false"
        :data="this.previewBox.data"
        :type="this.previewBox.type"
    />

    <!-- Вікно редагування повідомлення через редактор  -->
    <editText 
        v-if="editBox.show"
        @close="editBox.show = false"
        @saveEditLang="saveEditLang"
        :data="editBox.data"
        :obj="this.form"
        :typeMsg="short"
    />

</template>

<script>
import modal from '@/components/modal-medium' 
import BlotFormatter from 'quill-blot-formatter'
import ImageUploader from 'quill-image-uploader'; // Для завантаження зображення на сервер в QE
import previewMsg from '../view/preview/message'
import editText from '../create/editText'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { Messages } from '@/API_Dash.js'
import { MessagesInSky } from '@/API.js'
import { storeS } from '@/store'; // глобальне сховище даних
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логів в телеграм

let apiServe = new Messages()
let apiMessagesSky = new MessagesInSky()

export default {
    components: {
        modal,
        Multiselect,
        previewMsg,
        editText
    },
    props: ['editForm'],
    setup: () => {
        
        // Створюємо об'єкт для модулю quill-image-uploader
        const imageUploaderModule = {
            name: 'imageUploader',
            module: ImageUploader,
            options: {
                upload: (file) => {
                console.log("file > ", file);
                    
                return new Promise((resolve, reject) => {
                    const formData = new FormData()
                    formData.append('image', file)

                    axios.post('/upload-image', formData)
                    .then((res) => {
                        console.log(res)
                        resolve(res.data.url)
                    })
                    .catch((err) => {
                        reject('Upload failed')
                        console.error('Error:', err)
                    })
                })
                },
            },
        }

        // Створюємо об'єкт для модулю quill-blot-formatter
        const blotFormatterModule = {
            name: 'blotFormatter',
            module: BlotFormatter,
            options: {/* додайте необхідні опції */}
        }

        // Комбінуємо обидва модулі у відповідний формат для Quill
        const modules = {
            toolbar: {
                container: [['bold', 'italic', 'underline', 'strike'], ['image']],
            },
            ...imageUploaderModule,
            // ...blotFormatterModule,
        }

        return { modules }
    },
    data(){
        return{
            recipient:'-1',
            form: {
                type: 'alert', // тип повідомлення
                perms:[], // права для повідомлення
                view: 'onBothPlatforms', // видимість
                recipient: '', // одержувачі
                view: "-3",
                langs: {
                    "uk": ''
                },
                msgTitle: "",
                typeMsg: "newmessage",
                countries: []
            },
            editBox: {
                data: '',
                show: false
            },
            previewBox: {
                data: '',
                type: '',
                show: false
            },
            internalTypes: [
                { value: 'newmessage', text: this.$t('NewMessage') },
                { value: 'triggers', text: this.$t('triggers') }
            ],
            types: [
                { value: 'alert', text: this.$t('Alerts') },
                { value: 'update', text: this.$t('Updates') }
            ],
            listVisibility: [
                { value: '-3', text: this.$t('onBothPlatforms') },
                { value: '-2', text: this.$t('onlyTerminal') }, 
                { value: '-1', text: this.$t('onlyAdmin') }
            ],
            recipients: [
                { value: '-1', text: this.$t('allUsers') },
                { value: '-2', text: this.$t('admins') },
                { value: '', text: this.$t('User') }
            ], //  не змінювати порядок!
            countryMsg: [
                {
                    "value": "UA",
                    "label": this.$t('Ukraine')
                },
                {
                    "value": "USA",
                    "label": this.$t('USA'),
                },
                {
                    "value": "EC",
                    "label": this.$t('Europe') + " (" + this.$t('EuropeCountries') + " )",
                },
                {
                    "value": "ALL",
                    "label": this.$t('Allcountries'),
                },
            ],
            permsList: [
                {
                    "value": "1",
                    "label": this.$t('Products')
                },
                {
                    "value": "2",
                    "label": this.$t('statistics')
                },
                {
                    "value": "3",
                    "label": this.$t('Finance')
                },
                {
                    "value": "4",
                    "label": this.$t("Marketing")
                },
                {
                    "value": "5",
                    "label": this.$t('Settings')
                },
                {
                    "value": "6",
                    "label": this.$t('device_pos_terminal')
                },
                {
                    "value": "7",
                    "label": this.$t('apps')
                },
                {
                    "value": "100",
                    "label": this.$t('AllProducts')
                },
                {
                    "value": "101",
                    "label": this.$t('warehousesGoods')
                },
                {
                    "value": "102",
                    "label": this.$t('ingredients')
                },
                {
                    "value": "103",
                    "label": this.$t("cookCards")
                },
                {
                    "value": "104",
                    "label": this.$t('semiFinishedProducts')
                },
                {
                    "value": "105",
                    "label": this.$t("Services")
                },
                {
                    "value": "106",
                    "label": this.$t('onSale')
                },
                {
                    "value": "107",
                    "label": this.$t('WarehouseBalances')
                },
                {
                    "value": "108",
                    "label": this.$t('ShowItemСost')
                },
                {
                    "value": "109",
                    "label": this.$t('ShowProductQuantity')
                },
                {
                    "value": "110",
                    "label": this.$t('switchOnePrice')
                },
                {
                    "value": "201",
                    "label": this.$t('main')
                },
                {
                    "value": "202",
                    "label": this.$t('MovementofGoods')
                },
                {
                    "value": "203",
                    "label": this.$t('topClient')
                },
                {
                    "value": "204",
                    "label": this.$t('byDays')
                },
                {
                    "value": "205",
                    "label": this.$t('productSpend')
                },
                {
                    "value": "206",
                    "label": this.$t('topSales')
                },
                {
                    "value": "207",
                    "label": this.$t('topClient')
                },
                {
                    "value": "208",
                    "label": this.$t('byDays')
                },
                {
                    "value": "209",
                    "label": this.$t('Отчет о списаных товарах')
                },
                {
                    "value": "210",
                    "label": this.$t('salesDynamic')
                },
                {
                    "value": "211",
                    "label": this.$t('RevenueReport')
                },
                {
                    "value": "212",
                    "label": this.$t('shopReport')
                },
                {
                    "value": "213",
                    "label": this.$t('Inventory')
                },
                {
                    "value": "214",
                    "label": this.$t('dangerOperation')
                },
                {
                    "value": "215",
                    "label": this.$t('recomendedForBuy')
                },
                {
                    "value": "216",
                    "label": this.$t('statByTaxes')
                },
                {
                    "value": "217",
                    "label": this.$t('reportByMarketing')
                },
                {
                    "value": "218",
                    "label": 'ABC'
                },
                {
                    "value": "219",
                    "label": this.$t('topIncoming')
                },
                {
                    "value": "220",
                    "label": this.$t('loyaltyProgram')
                },
                {
                    "value": "221",
                    "label": this.$t('admin_reportsGoodscategory')
                },
                {
                    "value": "222",
                    "label": this.$t('admin_reportsRevenuereport')
                },
                {
                    "value": "223",
                    "label": this.$t('admin_reportsSalesdynamic')
                },
                {
                    "value": "224",
                    "label": this.$t("admin_reportsEmployee")
                },
                {
                    "value": "225",
                    "label": this.$t("admin_reportsInventory")
                },
                {
                    "value": "226",
                    "label": this.$t("admin_reportsGoods")
                },
                {
                    "value": "700",
                    "label": this.$t('ShowItemСost')
                },
                {
                    "value": "1000",
                    "label": this.$t('capital')
                },
                {
                    "value": "1001",
                    "label": this.$t('admin_reportsWalletsstatus')
                },
                {
                    "value": "1002",
                    "label": this.$t('magazine')
                },
                {
                    "value": "1003",
                    "label": this.$t('reports2')
                },
                {
                    "value": "1004",
                    "label": this.$t('Calendar')
                },
                {
                    "value": "1005",
                    "label": this.$t('admin_reportsFin')
                },
                {
                    "value": "1100",
                    "label": this.$t('admin_marketingClients')
                },
                {
                    "value": "1101",
                    "label": this.$t('admin_marketingOffers')
                },
                {
                    "value": "1102",
                    "label": this.$t('admin_marketingStock')
                },
                {
                    "value": "1103",
                    "label": this.$t('admin_marketingDiscount')
                },
                {
                    "value": "1104",
                    "label": this.$t('admin_marketingBonus')
                },
                {
                    "value": "1200",
                    "label": this.$t('admin_settingsglobal')
                },
                {
                    "value": "1201",
                    "label": this.$t('admin_settingstradepoints')
                },
                {
                    "value": "1202",
                    "label": this.$t('admin_settingscategory')
                },
                {
                    "value": "1203",
                    "label": this.$t('categIncome')
                },
                {
                    "value": "1204",
                    "label": this.$t('admin_settingsexpenses')
                },
                {
                    "value": "1205",
                    "label": this.$t('admin_settingstax')
                },
                {
                    "value": "1206",
                    "label": this.$t('warehouses')
                },
                {
                    "value": "1207",
                    "label": this.$t('Terminals')
                },
                {
                    "value": "1208",
                    "label": this.$t('admin_settingsworkshop')
                },
                {
                    "value": "1209",
                    "label": this.$t('admin_settingshalls')
                },
                {
                    "value": "1210",
                    "label": this.$t('admin_settingstables')
                },
                {
                    "value": "1211",
                    "label": this.$t('device')
                },
                {
                    "value": "1212",
                    "label": this.$t('users')
                },
                {
                    "value": "1213",
                    "label": this.$t('invoices')
                },
                {
                    "value": "1214",
                    "label": this.$t('navProjects')
                },
                {
                    "value": "1215",
                    "label": this.$t('admin_settingsprovider')
                },
                {
                    "value": "1216",
                    "label": this.$t('tags')
                },
                {
                    "value": "1217",
                    "label": this.$t('admin_settingsnotices')
                },
                {
                    "value": "1218",
                    "label": this.$t('accessToFillials')
                },
                {
                    "value": "1219",
                    "label": this.$t('positionsOfWorkers')
                },
                {
                    "value": "1220",
                    "label": this.$t('predictions')
                },
                {
                    "value": "1221",
                    "label": this.$t('currencies')
                },
                {
                    "value": "1222",
                    "label": this.$t('admin_settingsmybalance')
                },
                {
                    "value": "1400",
                    "label": this.$t('pos_inventory')
                },
                {
                    "value": "1401",
                    "label": this.$t('Incomings')
                },
                {
                    "value": "1402",
                    "label": this.$t('pos_writeOff')
                },
                {
                    "value": "1403",
                    "label": this.$t('pos_transfer')
                },
                {
                    "value": "1404",
                    "label": this.$t('cashOperations')
                },
                {
                    "value": "1405",
                    "label": this.$t('reportForDuty')
                },
                {
                    "value": "1406",
                    "label": this.$t('historyOfSale')
                },
                {
                    "value": "1407",
                    "label": this.$t('returns')
                },
                {
                    "value": "1408",
                    "label": this.$t('prinPreBill')
                },
                {
                    "value": "1409",
                    "label": this.$t('cancelOrder')
                },
                {
                    "value": "1410",
                    "label": this.$t('sortGoods')
                },
                {
                    "value": "1411",
                    "label": this.$t('splitBill')
                },
                {
                    "value": "1412",
                    "label": this.$t('closeBillWithoutPay')
                },
                {
                    "value": "1413",
                    "label": this.$t('CreateTransaction')
                },
                {
                    "value": "1414",
                    "label": this.$t('giveAccessToAdmin')
                },
                {
                    "value": "1415",
                    "label": this.$t('pos_produce')
                },
                {
                    "value": "1416",
                    "label": this.$t('pos_devices')
                },
                {
                    "value": "1417",
                    "label": this.$t('pos_openMoneyBox')
                },
                {
                    "value": "1418",
                    "label": this.$t("pos_deleteUnSynchronizedChecks")
                },
                {
                    "value": "1419",
                    "label": this.$t("pos_correctCheck")
                },
                {
                    "value": "1420",
                    "label": this.$t("pos_changeWayToPay")
                },
                {
                    "value": "2000",
                    "label": this.$t('Sales')
                },
                {
                    "value": "2001",
                    "label": this.$t('Coming')
                },
                {
                    "value": "2002",
                    "label": this.$t('returns')
                },
                {
                    "value": "2003",
                    "label": this.$t('WriteOff')
                },
                {
                    "value": "2004",
                    "label": this.$t('transfer2')
                },
                {
                    "value": "2005",
                    "label": this.$t('Production')
                },
                {
                    "value": "2006",
                    "label": this.$t('returnGoodToProvider')
                },
                {
                    "value": "3000",
                    "label": this.$t('sales_cancelBill')
                },
                {
                    "value": "3001",
                    "label": this.$t('sales_showCostPrice')
                },
                {
                    "value": "3100",
                    "label": this.$t("ParishCreation")
                },
                {
                    "value": "3101",
                    "label": this.$t("ParishEditing")
                },
                {
                    "value": "3102",
                    "label": this.$t("ParishCancel")
                },
                {
                    "value": "3103",
                    "label": this.$t('create_return')
                },
                {
                    "value": "3104",
                    "label": this.$t("edit_return")
                },
                {
                    "value": "3105",
                    "label": this.$t("remove_return")
                },
                {
                    "value": "3200",
                    "label": this.$t('cancel_order')
                },
                {
                    "value": "3201",
                    "label": this.$t('return_showCostPrice')
                },
                {
                    "value": "3300",
                    "label": this.$t('CreateWriteoff')
                },
                {
                    "value": "3301",
                    "label": this.$t('EditWriteoff')
                },
                {
                    "value": "3302",
                    "label": this.$t('CancelWriteoff')
                },
                {
                    "value": "3303",
                    "label": this.$t('writeOff_showCostPrice')
                },
                {
                    "value": "3400",
                    "label": this.$t('CreateMoving')
                },
                {
                    "value": "3401",
                    "label": this.$t('EditMoving')
                },
                {
                    "value": "3402",
                    "label": this.$t('CancelMoving')
                },
                {
                    "value": "3403",
                    "label": this.$t('moving_showCostPrice')
                },
                {
                    "value": "3500",
                    "label": this.$t('CreateProduction')
                },
                {
                    "value": "3501",
                    "label": this.$t('EditProduction')
                },
                {
                    "value": "3502",
                    "label": this.$t('CancelProduction')
                },
                {
                    "value": "3503",
                    "label": this.$t('production_showCostPrice')
                },
                {
                    "value": "3601",
                    "label": this.$t('CreateInventory')
                },
                {
                    "value": "3602",
                    "label": this.$t('EditInventory')
                },
                {
                    "value": "3603",
                    "label": this.$t("inventory_showCostPrice")
                },
                {
                    "value": "3700",
                    "label": this.$t('allTransactions')
                },
                {
                    "value": "3701",
                    "label": this.$t('incomings')
                },
                {
                    "value": "3702",
                    "label": this.$t("spendings")
                },
                {
                    "value": "3703",
                    "label": this.$t('movements')
                },
                {
                    "value": "3704",
                    "label": this.$t('corrective')
                },
                {
                    "value": "3705",
                    "label": this.$t('Edited')
                },
                {
                    "value": "3800",
                    "label": this.$t('addTransaction')
                },
                {
                    "value": "3801",
                    "label": this.$t("editTransaction")
                },
                {
                    "value": "3802",
                    "label": this.$t('deleteTransaction')
                },
                {
                    "value": "3803",
                    "label": this.$t("recoveryTransaction")
                },
                {
                    "value": "3900",
                    "label": this.$t("addSpendTranscation")
                },
                {
                    "value": "3901",
                    "label": this.$t("editSpendTranscation")
                },
                {
                    "value": "3902",
                    "label": this.$t('delSpendTranscation')
                },
                {
                    "value": "3903",
                    "label": this.$t('recSpendTranscation')
                },
                {
                    "value": "4000",
                    "label": this.$t("addTransferTransaction")
                },
                {
                    "value": "4001",
                    "label": this.$t('editTransferTransaction')
                },
                {
                    "value": "4002",
                    "label": this.$t('delTransferTransaction')
                },
                {
                    "value": "4003",
                    "label": this.$t('recTransferTransaction')
                },
                {
                    "value": "4100",
                    "label": this.$t('addCorrectTransaction')
                },
                {
                    "value": "4101",
                    "label": this.$t('delCorrectTransaction')
                },
                {
                    "value": "4200",
                    "label": this.$t('reports_contrAgents')
                },
                {
                    "value": "4201",
                    "label": "P&L"
                },
                {
                    "value": "4202",
                    "label": "Cashflow"
                },
                {
                    "value": "4203",
                    "label": "CashFlow Month"
                },
                {
                    "value": "4204",
                    "label": this.$t('depet')
                },
                {
                    "value": "4205",
                    "label": this.$t('balance')
                },
                {
                    "value": "4206",
                    "label": this.$t('reports_projects')
                },
                {
                    "value": "4207",
                    "label": this.$t('credit')
                },
                {
                    "value": "4300",
                    "label": this.$t('addClient')
                },
                {
                    "value": "4301",
                    "label": this.$t('removeClient')
                },
                {
                    "value": "4302",
                    "label": this.$t('saveClient')
                },
                {
                    "value": "4400",
                    "label": this.$t('addPropose')
                },
                {
                    "value": "4401",
                    "label": this.$t('delPropose')
                },
                {
                    "value": "4402",
                    "label": this.$t('editPropose')
                },
                {
                    "value": "4500",
                    "label": this.$t('addStockAk')
                },
                {
                    "value": "4501",
                    "label": this.$t('removeStockAk')
                },
                {
                    "value": "4502",
                    "label": this.$t('editStockAk')
                },
                {
                    "value": "4600",
                    "label": this.$t('addDiscount')
                },
                {
                    "value": "4601",
                    "label": this.$t('removeDiscount')
                },
                {
                    "value": "4602",
                    "label": this.$t('editDiscount')
                },
                {
                    "value": "4700",
                    "label": this.$t('addBonus')
                },
                {
                    "value": "4701",
                    "label": this.$t('removeBonus')
                },
                {
                    "value": "4702",
                    "label": this.$t('editBonus')
                },
                {
                    "value": "4800",
                    "label": this.$t('addTradePoint')
                },
                {
                    "value": "4801",
                    "label": this.$t('editTradePoint')
                },
                {
                    "value": "4802",
                    "label": this.$t('removeTradePoint')
                },
                {
                    "value": "4803",
                    "label": this.$t('giveAccess')
                },
                {
                    "value": "4804",
                    "label": this.$t('freezeTradePoint')
                },
                {
                    "value": "4805",
                    "label": this.$t('unFreezeTradePoint')
                },
                {
                    "value": "4806",
                    "label": this.$t('recoverTradePoint')
                },
                {
                    "value": "4900",
                    "label": this.$t('addProductsCateg')
                },
                {
                    "value": "4901",
                    "label": this.$t('editProductsCateg')
                },
                {
                    "value": "4902",
                    "label": this.$t('removeProductsCateg')
                },
                {
                    "value": "5000",
                    "label": this.$t('addCategIncome')
                },
                {
                    "value": "5001",
                    "label": this.$t('editCategIncome')
                },
                {
                    "value": "5002",
                    "label": this.$t('delCategIncome')
                },
                {
                    "value": "5003",
                    "label": this.$t('accessCategIncome')
                },
                {
                    "value": "5100",
                    "label": this.$t('addCatCost')
                },
                {
                    "value": "5101",
                    "label": this.$t('editCatCost')
                },
                {
                    "value": "5102",
                    "label": this.$t('delCatCost')
                },
                {
                    "value": "5103",
                    "label": this.$t('accessCatCost')
                },
                {
                    "value": "5200",
                    "label": this.$t('addTax')
                },
                {
                    "value": "5201",
                    "label": this.$t('editTax')
                },
                {
                    "value": "5202",
                    "label": this.$t('removeTax')
                },
                {
                    "value": "5300",
                    "label": this.$t('addWarehouse')
                },
                {
                    "value": "5301",
                    "label": this.$t('editWarehouse')
                },
                {
                    "value": "5302",
                    "label": this.$t('removeWarehouse')
                },
                {
                    "value": "5303",
                    "label": this.$t('accessWarehouses')
                },
                {
                    "value": "5400",
                    "label": this.$t("addTerminal")
                },
                {
                    "value": "5401",
                    "label": this.$t("editTerminal")
                },
                {
                    "value": "5402",
                    "label": this.$t("delTerminal")
                },
                {
                    "value": "5403",
                    "label": this.$t("accesTerminal")
                },
                {
                    "value": "5500",
                    "label": this.$t('addWorkshops')
                },
                {
                    "value": "5501",
                    "label": this.$t('editWorkshops')
                },
                {
                    "value": "5502",
                    "label": this.$t('removeWorkshops')
                },
                {
                    "value": "5503",
                    "label": this.$t('accesWorkshops')
                },
                {
                    "value": "5600",
                    "label": this.$t('addHall')
                },
                {
                    "value": "5601",
                    "label": this.$t('editHall')
                },
                {
                    "value": "5602",
                    "label": this.$t('delHall')
                },
                {
                    "value": "5700",
                    "label": this.$t('addTable')
                },
                {
                    "value": "5701",
                    "label": this.$t('editTable')
                },
                {
                    "value": "5702",
                    "label": this.$t('removeTable')
                },
                {
                    "value": "5800",
                    "label": this.$t('addDevice_perm')
                },
                {
                    "value": "5801",
                    "label": this.$t('editDevice')
                },
                {
                    "value": "5802",
                    "label": this.$t('delDevice')
                },
                {
                    "value": "5900",
                    "label": this.$t('addWorker')
                },
                {
                    "value": "5901",
                    "label": this.$t('editWorker')
                },
                {
                    "value": "5902",
                    "label": this.$t('delWorker')
                },
                {
                    "value": "5903",
                    "label": this.$t('workerAPI')
                },
                {
                    "value": "5904",
                    "label": this.$t('salaryWorker')
                },
                {
                    "value": "6000",
                    "label": this.$t('сreatingAccount')
                },
                {
                    "value": "6001",
                    "label": this.$t('editAccount')
                },
                {
                    "value": "6002",
                    "label": this.$t('delAccount')
                },
                {
                    "value": "6003",
                    "label": this.$t('accessAccount')
                },
                {
                    "value": "6100",
                    "label": this.$t('addProject')
                },
                {
                    "value": "6101",
                    "label": this.$t('editProject')
                },
                {
                    "value": "6102",
                    "label": this.$t('delProject')
                },
                {
                    "value": "6103",
                    "label": this.$t('recoveryProject')
                },
                {
                    "value": "6104",
                    "label": this.$t('accessProject')
                },
                {
                    "value": "6200",
                    "label": this.$t('addProvider')
                },
                {
                    "value": "6201",
                    "label": this.$t('editProvider')
                },
                {
                    "value": "6202",
                    "label": this.$t('delProvider')
                },
                {
                    "value": "6300",
                    "label": this.$t('terminal_showInv')
                },
                {
                    "value": "6301",
                    "label": this.$t('terminal_createInv')
                },
                {
                    "value": "6302",
                    "label": this.$t('terminal_editInv')
                },
                {
                    "value": "6400",
                    "label": this.$t('terminal_addInc')
                },
                {
                    "value": "6401",
                    "label": this.$t('terminal_editInc')
                },
                {
                    "value": "6402",
                    "label": this.$t('terminal_cancelInc')
                },
                {
                    "value": "6500",
                    "label": this.$t('terminal_addWriteOff')
                },
                {
                    "value": "6501",
                    "label": this.$t('terminal_editWriteOff')
                },
                {
                    "value": "6502",
                    "label": this.$t('terminal_cancelWriteOff')
                },
                {
                    "value": "6600",
                    "label": this.$t('terminal_addTransfer')
                },
                {
                    "value": "6601",
                    "label": this.$t('terminal_editTransfer')
                },
                {
                    "value": "6602",
                    "label": this.$t('terminal_cancelTransfer')
                },
                {
                    "value": "6700",
                    "label": this.$t('terminal_collection')
                },
                {
                    "value": "6701",
                    "label": this.$t('terminal_making')
                },
                {
                    "value": "6702",
                    "label": this.$t('terminal_expense')
                },
                {
                    "value": "6800",
                    "label": this.$t('terminal_addTransacIncom')
                },
                {
                    "value": "6801",
                    "label": this.$t('terminal_addTransacSpent')
                },
                {
                    "value": "6802",
                    "label": this.$t('terminal_addTransacTransfer')
                },
                {
                    "value": "6803",
                    "label": this.$t('terminal_addTransacCorrect')
                },
                {
                    "value": "6900",
                    "label": this.$t('addWaregouseGood')
                },
                {
                    "value": "6901",
                    "label": this.$t('editWaregouseGood')
                },
                {
                    "value": "6902",
                    "label": this.$t('delWaregouseGood')
                },
                {
                    "value": "6903",
                    "label": this.$t('delGoodsFromTrash')
                },
                {
                    "value": "6904",
                    "label": this.$t('recoveryGoodsFromTrash')
                },
                {
                    "value": "7000",
                    "label": this.$t('topSales_showSelfCost')
                },
                {
                    "value": "7100",
                    "label": this.$t('days_showSelfCost')
                },
                {
                    "value": "7200",
                    "label": this.$t('revenue_showSelfCost')
                },
                {
                    "value": "7300",
                    "label": this.$t('reportWorkShops_showSelfCost')
                },
                {
                    "value": "7400",
                    "label": this.$t('abc_goods')
                },
                {
                    "value": "7401",
                    "label": this.$t('abc_categsGood')
                },
                {
                    "value": "7500",
                    "label": this.$t('addTag')
                },
                {
                    "value": "7501",
                    "label": this.$t('renameTag')
                },
                {
                    "value": "7502",
                    "label": this.$t('delTag')
                },
                {
                    "value": "7600",
                    "label": this.$t('edit_editedTransaction')
                },
                {
                    "value": "7601",
                    "label": this.$t('del_editedTransaction')
                },
                {
                    "value": "7602",
                    "label": this.$t('recover_editedTransaction')
                },
                {
                    "value": "7700",
                    "label": this.$t('active_app')
                },
                {
                    "value": "7701",
                    "label": this.$t('access_app')
                },
                {
                    "value": "7800",
                    "label": 'skymarket'
                },
                {
                    "value": "7801",
                    "label": 'tpay'
                },
                {
                    "value": "7802",
                    "label": 'sendPulse'
                },
                {
                    "value": "7803",
                    "label": 'hallScreen'
                },
                {
                    "value": "7804",
                    "label": 'sellup'
                },
                {
                    "value": "7805",
                    "label": 'monobank'
                },
                {
                    "value": "7806",
                    "label": 'privat'
                },
                {
                    "value": "7807",
                    "label": 'shakeToPay'
                },
                {
                    "value": "7808",
                    "label": 'bookBeauty'
                },
                {
                    "value": "7809",
                    "label": 'bookingTable'
                },
                {
                    "value": "7810",
                    "label": 'sendcheck'
                },
                {
                    "value": "7900",
                    "label": this.$t('marketing_bonuses')
                },
                {
                    "value": "8000",
                    "label": this.$t('terminal_createProduce')
                },
                {
                    "value": "8001",
                    "label": this.$t('terminal_editProduce')
                },
                {
                    "value": "8002",
                    "label": this.$t('terminal_delProduce')
                },
                {
                    "value": "8100",
                    "label": this.$t('addIngredient')
                },
                {
                    "value": "8101",
                    "label": this.$t('editIngredient')
                },
                {
                    "value": "8102",
                    "label": this.$t('delIngredient')
                },
                {
                    "value": "8200",
                    "label": this.$t('addCookingCar')
                },
                {
                    "value": "8201",
                    "label": this.$t('editCookingCard')
                },
                {
                    "value": "8202",
                    "label": this.$t('delCookingCard')
                },
                {
                    "value": "8300",
                    "label": this.$t('addSemiProduct')
                },
                {
                    "value": "8301",
                    "label": this.$t('editSemiProduct')
                },
                {
                    "value": "8302",
                    "label": this.$t('delSemiProduct')
                },
                {
                    "value": "8400",
                    "label": this.$t('addServices')
                },
                {
                    "value": "8401",
                    "label": this.$t('editServices')
                },
                {
                    "value": "8402",
                    "label": this.$t('delServices')
                },
                {
                    "value": "8500",
                    "label": this.$t('addPosition')
                },
                {
                    "value": "8501",
                    "label": this.$t('editPosition')
                },
                {
                    "value": "8502",
                    "label": this.$t('delPosition')
                },
                {
                    "value": "8503",
                    "label": this.$t('recoveryPosition')
                },
                {
                    "value": "8600",
                    "label": this.$t('addPredictions')
                },
                {
                    "value": "8601",
                    "label": this.$t('editPredictions')
                },
                {
                    "value": "8602",
                    "label": this.$t('delPredictions')
                },
                {
                    "value": "8700",
                    "label": this.$t('addCurrency')
                },
                {
                    "value": "8701",
                    "label": this.$t('editCurrency')
                },
                {
                    "value": "8702",
                    "label": this.$t('delCurrency')
                },
                {
                    "value": "9000",
                    "label": this.$t('terminal_addDevice')
                },
                {
                    "value": "9001",
                    "label": this.$t('terminal_editDevice')
                },
                {
                    "value": "9002",
                    "label": this.$t('terminal_delDevice')
                },
                {
                    "value": "9003",
                    "label": this.$t('terminal_activeDevice')
                },
                {
                    "value": "9100",
                    "label": this.$t('seeReplenishBalance')
                },
                {
                    "value": "9101",
                    "label": this.$t('seeWriteOffBalance')
                },
                {
                    "value": "9102",
                    "label": this.$t('myAccount')
                },
                {
                    "value": "9200",
                    "label": this.$t('terminal_seeListInk')
                },
                {
                    "value": "9201",
                    "label": this.$t('terminal_seeListIncoms')
                },
                {
                    "value": "9202",
                    "label": this.$t('terminal_seeListSpents')
                },
                {
                    "value": "9300",
                    "label": this.$t('abilityToSend')
                },
                {
                    "value": "9301",
                    "label": this.$t('abilityToCheckArchive')
                }
            ],
            availableLangs: [
                // {
                //     "value": '',
                //     "title": "null"
                // },
                {
                    "value": 'fr',
                    "title": "Français"
                },
                {
                    "value": "en",
                    "title": "English"
                },
                {
                    "value": "ru",
                    "title": "Русский"
                },
                {
                    "value": "de",
                    "title": "Deutsch"
                },
                {
                    "value": "es",
                    "title": "Español"
                },
                {
                    "value": "pl",
                    "title": "Polski"
                },
                {
                    "value": "uk",
                    "title": "Українська"
                },
                {
                    "value": "it",
                    "title": "Italiano"
                },
                {
                    "value": "he",
                    "title": "עִבְרִית"
                },
                {
                    "value": "tr",
                    "title": "Türkçe"
                },
                {
                    "value": "et",
                    "title": "eesti keel"
                },
                {
                    "value": "be",
                    "title": "Беларуская мова"
                },
                {
                    "value": "ar",
                    "title": "العربية"
                },
                {
                    "value": "fi",
                    "title": "suomi"
                },
                {
                    "value": "lt",
                    "title": "lietuvių kalba"
                },
                {
                    "value": "tg",
                    "title": "Забони тоҷикӣ"
                },
                {
                    "value": "pt",
                    "title": "Português"
                },
                {
                    "value": "ka",
                    "title": "Georgian (Georgia)"
                },
                {
                    "value": "cn",
                    "title": "Chinese Simplified"
                },
                {
                    "value": "el",
                    "title": "Greek"
                },
                {
                    "value": "km",
                    "title": "Khmer (Cambodia)"
                },
                {
                    "value": "lv",
                    "title": "Latvian"
                },
                {
                    "value": "ro",
                    "title": "Romanian"
                },
                {
                    "value": "th",
                    "title": "ภาษาไทย (Thai)"
                }
            ],
            arrayForEdit: [],
            langsForPattern: [],
            processedHTML: ''
        }
    },
    methods: {
        editPatern(){
            // сейвимо текст з масиву для редагування
            this.arrayForEdit.forEach(el => {
                console.log(el.lang, el.text)
                this.form.langs[el.lang] = el.text
            })
            // сейвимо текст з масиву для редагування
            apiMessagesSky.editMessage(this.editForm.id, this.editForm).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('alertChanged'))
                    this.$emit('close')
                    this.$emit('getdata')
                } else{
                    this.$toast.error(this.$t('error'))

                    var errorText = 'Помилка редагування повідомлень'; 
                    var statusRequest = result.status;
                    var api = 'editMessage';
                    var fileRequest = 'src/views/messages/create/create-message.vue';
                    var params = {id: this.editForm.id, form: this.editForm};
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        input(text){
            const parser = new DOMParser();
            const doc = parser.parseFromString(text.langs["uk"], 'text/html');

            if (doc.body) {
                const elements = doc.body.querySelectorAll('*');

                elements.forEach(element => {
                    const tagName = element.tagName.toLowerCase();

                    switch (tagName) {
                        case 'h1':
                            element.classList.add('notification-title-1');
                        break;
                        case 'h2':
                            element.classList.add('notification-title-2');
                        break;
                        case 'h3':
                            element.classList.add('notification-title-3');
                        break;
                        case 'h4':
                            element.classList.add('notification-title-4');
                        break;
                        case 'h5':
                            element.classList.add('notification-title-5');
                        break;
                        case 'h6':
                            element.classList.add('notification-title-6');
                        break;
                        case 'p':
                            element.classList.add('notification-text');
                        break;
                        case 'ul':
                            element.classList.add('notification-list');
                        break;
                        case 'ol':
                            element.classList.add('notification-ordered-list');
                        break;
                        case 'li':
                            if (element.parentElement.tagName.toLowerCase() === 'ul') {
                                element.classList.add('notification-list__item');
                            } else if (element.parentElement.tagName.toLowerCase() === 'ol') {
                                element.classList.add('notification-ordered-list__item');
                            }
                        break;
                        case 'a':
                            element.classList.add('notification-link');
                        break;
                        case 'img':
                            element.classList.add('notification-img');
                        break;
                        case 'strong':
                            element.classList.add('notification-strong');
                        break;
                        default:
                        break;
                    }
                });

                // Отримуємо оброблений HTML знову
                this.processedHTML = doc.body.innerHTML;
            }
        },
        changeInEdit(idx, text) {
            this.arrayForEdit[idx].text = text
            console.log(this.arrayForEdit)
        },
        switchLangInEdit(k, e) {
            //додаємо властивості
            console.log(k, e, this.arrayForEdit)
            this.arrayForEdit[k]['lang'] = e.target.value
            console.log(this.arrayForEdit)
        },
        switchText(idx, text) {
            this.langsForPattern[idx][Object.keys(this.langsForPattern[idx])[0]] = text
            console.log(this.langsForPattern[idx])
        },
        editLang(idx) {
            this.langsForPattern[idx]['edit'] = !this.langsForPattern[idx]['edit']
        },
        switchLang(idx, lang) {
            let obj = {} // формуємо об'єкт на заміну
            //додаємо властивості
            obj["" + lang.target.value] = '' 
            obj["edit"] = true
            //додаємо властивості
            this.langsForPattern[idx] = obj // заміняємо
        },
        deleteKey(e, index){ 
            console.log(e, index)
            this.langsForPattern.splice(index, 1)
        },
        addLang() { 
            // Додати мову в переклад повідомлення

            // Масив мов з пріоритетом
            const priorityLanguages = ['uk', 'ru', 'en', 'pl'];

            // Перевірка пріоритетних мов
            for (const lang of priorityLanguages) {
                const exists = this.langsForPattern.some(item => Object.keys(item)[0] === lang);

                // Якщо мова не існує у langsForPattern, додати її з порожнім значенням та edit = true
                if (!exists) {
                    this.langsForPattern.push({
                        [lang]: '',
                        edit: true
                    });
                    return; // Зупинити додавання після першої знайденої відсутньої мови
                }
            }

            // Якщо всі пріоритетні мови вже існують, додати будь-яку іншу мову з порожнім значенням
            const otherLanguage = this.availableLangs.find(lang => !this.langsForPattern.some(item => Object.keys(item)[0] === lang.value));

            if (otherLanguage) {
                this.langsForPattern.push({
                    [otherLanguage.value]: '',
                    edit: true
                });
            }
        },
        addLangInEdit() { 
            // Додати мову в переклад повідомлення

            // Масив мов з пріоритетом
            const priorityLanguages = ['uk', 'ru', 'en', 'pl'];

            // Перевірка пріоритетних мов
            for (const lang of priorityLanguages) {
                const exists = this.arrayForEdit.some(item => item[Object.keys(item)[0]] === lang);
                console.log(exists)
                // Якщо мова не існує у langsForPattern, додати її з порожнім значенням та edit = true
                if (!exists) {
                    console.log(lang)
                    this.arrayForEdit.push({
                        lang: lang,
                        edit: true,
                        text: ''
                    });
                    return; // Зупинити додавання після першої знайденої відсутньої мови
                }
            }

            // Якщо всі пріоритетні мови вже існують, додати будь-яку іншу мову з порожнім значенням
            const otherLanguage = this.availableLangs.find(lang => !this.arrayForEdit.some(item => item[Object.keys(item)[0]] === lang.value));

            if (otherLanguage) {
                this.arrayForEdit.push({
                    lang: otherLanguage.value,
                    edit: true,
                    text: ''
                });
            }
        },
        addAllLangInEdit(){
            // Додати всі мови перекладу

            // Перевірка кожної мови з availableLangs
            this.availableLangs.forEach(lang => {
                // Перевірка, чи мова вже є в langsForPattern
                const exists = this.arrayForEdit.some(item => item[Object.keys(item)[0]] === lang.value);

                // Якщо мова відсутня, додаємо її з порожнім значенням та властивістю edit = false
                if (!exists) {
                    this.arrayForEdit.push({
                        lang: lang.value,
                        edit: false,
                        text: ''
                    });
                }
            });

            console.log("langsForPattern", this.arrayForEdit);
            
        },
        addAllLang(){
            // Додати всі мови перекладу

            // Перевірка кожної мови з availableLangs
            this.availableLangs.forEach(lang => {
                // Перевірка, чи мова вже є в langsForPattern
                const exists = this.langsForPattern.some(item => Object.keys(item)[0] === lang.value);

                // Якщо мова відсутня, додаємо її з порожнім значенням та властивістю edit = false
                if (!exists) {
                    this.langsForPattern.push({
                        [lang.value]: '',
                        edit: false
                    });
                }
            });

            console.log("langsForPattern", this.langsForPattern);
            
        },
        translateOneKey(lang, text) { // перекладаємо один ключ
            if(!text) {
                text = this.form.langs['uk'] // беремо український текст, якщо не передали його
            }
            let idx;
            console.log(lang)
            if(typeof lang == 'number') {
                if(this.editForm) {
                    idx = lang
                    lang = Object.keys(this.form.langs)[idx]
                } else {
                    idx = lang
                    lang = Object.keys(this.langsForPattern[lang])[0]
                }
            }
            let obj = {
                "text": this.form.langs['uk'],
                "targetLang": lang,
                "sourceLang": 'uk'
            } // збираємо об'єкт
            if(this.langsForPattern[idx]) {
                this.langsForPattern.splice(idx, 1)
            }
            apiServe.translateOneKey(obj).then(result => {
                if(result.status == 'done') {
                    if(this.editForm) {
                        console.log("this.form.langs >> ",this.form.langs)
                        this.form.langs[lang] = result.data.translate.text
                    } else {
                        let obj = {}
                        obj[lang] = result.data.translate.text
                        obj['edit'] = false
                        this.langsForPattern.push(obj)
                    }
                }
            })
        },
        translateOneKeyInEdit(lang) { // перекладаємо один ключ
            let obj = {
                "text": this.form.langs['uk'],
                "targetLang": this.arrayForEdit[k].lang,
                "sourceLang": 'uk'
            } // збираємо об'єкт
            apiServe.translateOneKey(obj).then(result => {
                if(result.status == 'done') {
                    this.arrayForEdit[k].text = result.data.translate.text
                }
            })
        },
        translateAll(array, text) {
            let obj = {}
            if(!array && !text) {
                console.log(this.langsForPattern)
                let langArr = []
                this.langsForPattern.forEach(el => {
                    langArr.push(Object.keys(el)[0])     
                }) //прибираємо мови, що вже мають переклад
                console.log(langArr)

                obj = {
                    text: this.form.langs['uk'],
                    sourceLang: 'uk',
                    targetLangs: langArr
                }
            } else {
                obj = {
                    text: text,
                    sourceLang: 'uk',
                    targetLangs: array
                }
            }
            apiServe.translateAllKey(obj).then(result => {
                if(result.status == 'done') {
                    Object.keys(result.data.translates).forEach(el => {
                        let obj = {}
                        obj[el] = result.data.translates[el].text
                        obj["edit"] = false
                        this.langsForPattern.push(obj)
                        this.form.langs[el] = result.data.translates[el].text
                    })
                } else {
                    this.$toast.error(this.$t('error'))

                    var errorText = 'Помилка пререкладу повідомлення'; 
                    var statusRequest = result.status;
                    var api = 'translateAllKey';
                    var fileRequest = 'src/views/messages/create/create-message.vue';
                    var params = obj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        translateAllInEdit() {
            console.log(this.langsForPattern)
            let langArr = []
            this.arrayForEdit.forEach(el => {
                langArr.push(el[Object.keys(el)[0]])                
            }) //прибираємо мови, що вже мають переклад
            console.log(langArr)

            let obj = {
                text: this.form.langs['uk'],
                sourceLang: 'uk',
                targetLangs: langArr
            }

            apiServe.translateAllKey(obj).then(result => {
                if(result.status == 'done') {
                    Object.keys(result.data.translates).forEach(el => {
                        this.arrayForEdit.push({
                            lang: el, 
                            edit: false, 
                            text: result.data.translates[el].text
                        })
                    })
                } else {
                    this.$toast.error(this.$t('error'))

                    var errorText = 'Помилка пререкладу повідомлення'; 
                    var statusRequest = result.status;
                    var api = 'translateAllKey';
                    var fileRequest = 'src/views/messages/create/create-message.vue';
                    var params = obj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        addPatern() {
            console.log(this.langsForPattern, this.form)
            if(this.recipient != '') {
                this.form.recipient = this.recipient
            }
            this.form.langs = {}
            this.langsForPattern.forEach(el => {
                this.form.langs[Object.keys(el)[0]] = Object.values(el)[0]
            })
            if(this.form.perms == ['']) {
                this.form.perms = []
            } 
            apiMessagesSky.addMessage(this.form).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t('Added'))
                    this.$emit('close')
                    this.$emit('getdata')
                } else {
                    this.$toast.error(this.$t('error'))

                    var errorText = 'Помилка додавання повідомлення'; 
                    var statusRequest = result.status;
                    var api = 'addMessage';
                    var fileRequest = 'src/views/messages/create/create-message.vue';
                    var params = this.form;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        previewShow(item, k){
            // Виклик вікна предперегляду повідомлення
            
            this.previewBox.data = item;
            this.previewBox.type = this.form.type;
            this.previewBox.show = true
        },
        editTextLang(item, key, k){
            // Редагування повідомлення через візуальний редактор
            console.log(item, key, k)
            this.editBox.data = {
                "text": item.text || item.text == '' ? item.text : item,
                "lang": this.availableLangs.find(el => key == el.value).title,
                "keyLang": key,
                "k": k
            };
            this.editBox.show = true
            console.log("editbox ", this.editBox);
            
        },
        saveEditLang(text, form){
            // Збереження відредагованого повідомлення до мови
            if (this.editForm) {
                this.arrayForEdit[form.k].text = text
            } else {
                this.langsForPattern[form.k][form.keyLang] = text
            }
        },
        setCountry(){
            // Вибір регіонів, в яких буде відображатись повідомлення

            console.log("countryMsg", this.countryMsg);
            var c_arr = []
            for(var item in this.form.countries){
                if(this.form.countries[item] == 'UA'){
                    c_arr.push('UA')
                } 
            }
        }
    },
    created() {
        if(this.editForm) {
            console.log(this.editForm)

            Object.keys(this.editForm.langs).forEach(el => {
                this.arrayForEdit.push({lang: el, edit: false, text: this.editForm.langs[el]})
            })

            if(this.editForm.recipient != '') {
                this.recipient = this.editForm.recipient
            } else {
                console.log("recipient > ", this.form.recipient);
                
                this.recipient = this.form.recipient
            }
            this.form = this.editForm 
        } 
    },
    computed: {
        user(){
            /*
                Повертає дані за користувача з глобальномго стору.
            */
            return storeS.userbase
        },
        perms(){
            /*
                Властивість, яка повертає права доступу користувача зі збережених даних у глобальному стору.
            */
            return storeS.perms
        }
    }
}
</script>