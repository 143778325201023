<template>
    <!-- 
      Модальне вікно з відображення картки повідомлення
      - налаштування повідомлення
      - короткий текст
      - детальний текст
    -->
  
    <!-- 
      Компонент модального вікна 
      - :title - заголовок вікна
      - :stylebody - стилі вікна
    -->
    <modal 
      :key="this.componentKey"
      :title="form.messageName" 
      :stylebody="'var(--vz-body-bg)'"
      @close="$emit('close')"
    >
      <!-- Слот для назви модального вікна -->
      <template v-slot:title>
        {{ this.mutateTypeMessage(form.type).name }} 
      </template>

      <!-- Слот статусу -->
      <template v-slot:status>
        <span 
          :class="this.mutateStatusMessage(this.form.status).color"
        >
          {{ this.mutateStatusMessage(this.form.status).name }}
        </span>
      </template>

      <!-- Слот шапки модального вікна (кнопки по праву сторону шапки) -->
      <template v-slot:head-button>

        <!-- Відображаємо статус у випадку нового повідомлення -->
        <div class="dropdown" v-if="this.form.status == 'new'">
          <button 
            class="btn btn-ghost-secondary btn-icon btn-sm fs-16" 
            type="button"
            data-bs-toggle="dropdown" 
            aria-haspopup="true" 
            aria-expanded="false"
          >
            <i class="bx bx-dots-vertical-rounded align-bottom"></i>
          </button>

          <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">

            <!-- Додати детальну інформацію -->
            <button 
              class="dropdown-item" 
              v-if="!form.more_langs['uk']" 
              @click="this.showCreateDesc = true"
            >
              {{ $t('addInfo') }}
            </button>

            <!-- Редагувати детальну інформацію -->
            <button
              v-else  
              class="dropdown-item" 
              @click="this.editMore = this.form; this.showCreateDesc = true"
            >
              {{ $t('editInfo') }}
            </button>

          </div>
        </div>
  
      </template>
  
      <template v-slot:body>


        <b-card v-if="form.msgTitle || form.typeMsg">
          <b-row>
            
            <!-- назва повідомлення (внутрішня) -->
            <b-col lg="6" v-if="form.msgTitle">
                <div class="mb-3">
                    <h4 class="fs-15">{{ $t('Point_name') }}</h4>
                    <input class="form-control" :value="form.msgTitle" disabled />
                </div>
            </b-col>

            <!-- внутрішній тип повідомлення -->
            <b-col lg="6" v-if="form.typeMsg">
                <div class="mb-3">
                    <h4 class="fs-15">{{ $t('type') }}</h4>
                    <input class="form-control" :value="$t(form.typeMsg)" disabled />
                </div>
            </b-col>

          </b-row>
        </b-card>

        <b-row>
          <!-- Інформація про повідомлення -->
          <b-col
            md="12"
          >
            <b-card>
              <h1 class="mb-2 pb-3 border-bottom" style="color: #009688">
                <b>{{ this.$t('Details') }}</b>
              </h1>

              <div class="row">

                <!-- Блок з типом повідомлення (оновлення, сповіщення) -->
                <div class="col-md-4 p-3">
                  <div class="mb-1" >
                    <div class="d-flex mt-2" >
                      <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div class="avatar-title bg-info rounded-circle fs-16 text-white" >
                          <i class="bx bx-task"></i>
                        </div>
                      </div>
                      <div class="flex-grow-1 overflow-hidden" >
                          <p class="mb-1 fs-16">{{ $t('type') }} :</p>
                          <div class="ctext-wrap" style="display: flex;" >
                            <div class="ctext-wrap-content" >
                              <h6 class="fs-14 mb-0 fw-normal copy">
                                {{ this.mutateTypeMessage(form.type).name }}
                              </h6>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Блок видимості повідомлення -->
                <div class="col-md-4 p-3">
                  <div class="mb-1" >
                    <div class="d-flex mt-2" >
                      <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div class="avatar-title bg-info rounded-circle fs-16 text-white" >
                          <i class="bx bx-task"></i>
                        </div>
                      </div>
                      <div class="flex-grow-1 overflow-hidden" >
                        <p class="mb-1 fs-16">{{ $t('Visibility') }} :</p>
                        <div class="ctext-wrap" style="display: flex;" >
                          <div class="ctext-wrap-content" >
                            <h6 class="fs-14 mb-0 fw-normal copy">
                              {{ this.mutateViewMessage(form.view) }}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Блок отримувачів повідомлення -->
                <div class="col-md-4 p-3">
                  <div class="mb-1" >
                    <div class="d-flex mt-2" >
                      <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div class="avatar-title bg-info rounded-circle fs-16 text-white" >
                          <i class="bx bx-task"></i>
                        </div>
                      </div>
                      <div class="flex-grow-1 overflow-hidden" >
                        <p class="mb-1 fs-16">{{ $t('recipient') }} :</p>
                        <div class="ctext-wrap" style="display: flex;" >
                          <div class="ctext-wrap-content" >
                            <h6 class="fs-14 mb-0 fw-normal copy">
                              {{ this.mutateRecipientMessage(form.recipient) }} 
                              <span 
                                v-if="this.form.recipient.length > 2"
                                class="text-muted" 
                                @click="copy(this.form.recipient)"
                              > 
                                ({{ this.form.recipient }})
                              </span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Блок обраних прав доступу для перегляду оновлення -->
                <div 
                  class="col-md-12 p-3" 
                  v-if="this.form.perms && this.form.perms.length > 0"
                >
                  <div class="mb-1" >
                    <div class="d-flex mt-2" >
                      <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div class="avatar-title bg-info rounded-circle fs-16 text-white" >
                          <i class="bx bx-task"></i>
                        </div>
                      </div>
                      <div class="flex-grow-1 overflow-hidden" >
                        <p class="mb-1 fs-16">{{ $t('perms') }} :</p>
                        <div class="ctext-wrap" style="display: flex;" >
                          <div class="ctext-wrap-content" >
                            <h6 class="fs-14 mb-0 fw-normal copy">
                              <template v-for="item in this.form.perms" :key="item">
                                <span class="badge bg-info m-1 fs-12">
                                  {{ mutatePerms(item) }}
                                </span>
                              </template>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </b-card>
          </b-col>
        </b-row>
          
        <!-- Блок з текстом повідомлення/оповіщення на різних мовах -->
        <div
          v-if="form.langs['uk']"
          id="knowledge-base-article"
        >
          <b-row>
            
            <b-col
              md="12"
            >
              <b-card>

                <div class="row border-bottom">
                  <div class="col">
                    <!-- Тип повідомлення -->
                    <h1 class="mb-2 pb-1" >
                      <b style="color: #009688">
                        {{ this.mutateTypeMessage(form.type).name }}
                      </b>
                    </h1>
                  </div>
                </div>

                <!-- Рядок для відображення кожного елемента мови -->
                <div class="row pt-3">

                  <!-- Блоки для кожного елемента мови -->
                  <div 
                    class="d-flex" 
                    v-for="item, k in Object.keys(this.form.langs)" 
                    :key="k" 
                    style="margin-bottom: 10px; gap: 10px;"
                  >
                    <!-- Відображення назви мови -->
                    <div class="flex-grow-3">
                      <select 
                        class="form-select" 
                        disabled
                      >
                          <option 
                            v-for="items, i in Object.keys(this.form.langs)" 
                            :value="items.value" 
                            :key="i" 
                            :selected="item == items"
                            disabled
                          >
                            {{ this.availableLangs.find(el => items == el.value).title }}
                          </option>
                      </select>
                    </div>

                    <!-- Повідомлення з версткою -->
                    <div class="flex-grow-1">
                      <input 
                        type="text" 
                        class="form-control" 
                        :value="this.form.langs[item]" 
                        @change="this.switchText(k, $event.target.value)"
                        disabled
                      >
                    </div>

                    <div class="flex-grow-2">

                      <!-- Кнопка копіювання тексту з версткою -->
                      <button 
                        class="btn btn-success" 
                        style="margin: 0 5px" 
                        @click="copy(this.form.langs[item])"
                        title="Скопіювати"
                      >
                        <i class='bx bx-copy'></i>
                      </button>

                      <!-- Кнопка предперегляду детальної інформації враховуючи мову -->
                      <button 
                        class="btn btn-info" 
                        style="margin: 0 5px" 
                        @click="previewShow(item, k, 'short')"
                        title="Предперегляд"
                      >
                        <i class='bx bxs-show' ></i>
                      </button>

                    </div>
                  </div>
                </div>
  
              </b-card>
            </b-col>
          </b-row>

          <b-row>
            <!-- Кнопка додавання детальної інформації -->
            <button 
              type="button" 
              class="mt-2 btn btn-soft-info waves-effect waves-light w-100 fw-semibold" 
              style="height: 48px;" 
              v-if="!form.more_langs['uk']" 
              @click="this.showCreateDesc = true"
            >
              {{this.$t('addInfo')}}
            </button>
          </b-row>

          <!-- Блок з детальним текстом повідомлення/оповіщення на різних мовах -->
          <b-row v-if="this.form.more_langs['uk']">
            
            <b-col
              md="12"
            >
              <b-card>
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <!-- Заголовок блоку -->
                    <h1 class="mb-2 pb-3 border-bottom" style="color: #009688">
                      <b>{{ $t('DetailedMessageLangs') }}</b>
                    </h1>
                  </div>

                  <div class="flex-shrink-0">
                    <!-- 
                        Кнопка "Копіювати"
                        - опція для редагування повідомлення
                    -->
                    <button 
                      class="btn btn-light" 
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Edit"
                      v-if="form.more_langs['uk']" 
                      @click="this.editMore = this.form; this.showCreateDesc = true"
                    >
                      <i class="ri-pencil-line align-bottom"></i>
                    </button>
                  </div>
                </div>
                <!-- Рядок для відображення кожного елемента мови -->
                <div class="row pt-3">
                  <!-- Блоки для кожного елемента мови -->
                  <div 
                    class="d-flex" 
                    v-for="item, k in Object.keys(this.form.more_langs)" 
                    :key="k" 
                    style="margin-bottom: 10px; gap: 10px;"
                  >
                    <!-- Відображення назви мови -->
                    <div class="flex-grow-3">
                      <select 
                        class="form-select" 
                        disabled
                      >
                          <option 
                            v-for="items, i in Object.keys(this.form.more_langs)" 
                            :value="items.value" 
                            :key="i" 
                            :selected="item == items"
                            disabled
                          >
                            {{ this.availableLangs.find(el => items == el.value).title }}
                          </option>
                      </select>
                    </div>

                    <!-- Детальне повідомлення з версткою -->
                    <div class="flex-grow-1">
                      <input 
                        type="text" 
                        class="form-control" 
                        :value="this.form.more_langs[item]" 
                        @change="this.switchText(k, $event.target.value)"
                        disabled
                      >
                    </div>

                    <div class="flex-grow-2">

                      <!-- Кнопка копіювання тексту з версткою -->
                      <button 
                        class="btn btn-success" 
                        style="margin: 0 5px" 
                        @click="copy(this.form.more_langs[item])"
                        title="Скопіювати"
                      >
                        <i class='bx bx-copy'></i>
                      </button>

                      <!-- Кнопка предперегляду детальної інформації враховуючи мову -->
                      <button 
                        class="btn btn-info" 
                        style="margin: 0 5px" 
                        @click="previewShow(item, k, 'more')"
                        title="Предперегляд"
                      >
                        <i class='bx bxs-show' ></i>
                      </button>

                    </div>
                  </div>
                </div>
  
              </b-card>
            </b-col>
          </b-row>
        </div>
      </template>
  
      <!-- Слот футера модального вікна з кнопками -->
      <template v-slot:footer-bottom>
  
        <!-- 
          Кнопка "Видалити" 
          - надає змогу видалити повідомлення
          - відкривається модальне вікно, для підтвердження видалення повідомлення
          - йде перевірка на права доступу під номером 1903 (видалення) та 2000 (режим бога)
        -->
        <button 
          v-if="this.perms[1903] || this.perms[2000]"
          type="button" 
          @click="showDialogRemove = true" 
          class="btn btn-danger"
        >
          {{ $t('Delete') }}
        </button>
  
        <!-- 
          Кнопка "Редагувати" 
          - надає змогу відредагувати картку повідомлення
          - відкривається модальне вікно, для редагування повідомлення
          - йде перевірка на права доступу під номером 1902 (редагування) та 2000 (режим бога)
        -->
        <button 
          v-if="this.form.status != 'confirmed' && (this.perms[1902] || this.perms[2000])"
          type="button" 
          @click="edit(form)" 
          class="btn btn-info"
        >
          {{ $t('Edit') }}
        </button>
        
        <!--  
          Кнопка "Підветрдити"
           - підтверджує повідомлення, що допоможе його відправити потім
        -->
        <button 
          v-if="this.form.status != 'confirmed' && (this.perms[1904] || this.perms[2000])"
          type="button" 
          @click="confirm(this.form.id)" 
          class="btn btn-success"
        >
          {{ $t('Confirm') }}
        </button>
        
        <!--  
          Кнопка "Відправити"
           - надсилає повідомлення в адмінку/постермінал (клієнтам)
        -->
        <button 
          v-if="this.form.status == 'confirmed' && (this.perms[1905] || this.perms[2000])"
          type="button" 
          @click="send(this.form)" 
          class="btn btn-success"
        >
          {{ $t('Send') }}
        </button>
  
      </template>
    </modal>
  
    <!-- 
      Модальне вікно створення детального повідомлення для оповіщення
      - йде перевірка showCreateDesc
      - @close - подія закриття модалки
      - @updateCard - подія, щоб оновити дані картки 
      - :obj - дані для картки
      - :id - айді для картки
    -->
    <createDesc
      v-if="showCreateDesc == true"
      @close="showCreateDesc = false;"
      @updateCard="updateCard"
      :obj="this.form"
      :id = "this.form.id"
    ></createDesc>

    <!-- 
      Модальне вікно підтвердження видалення повідомлення
      - йде перевірка showDialogRemove
      - @close - подія закриття модалки
      - @close - подія видалення повідомлення
    -->
    <removeModal
      v-if="showDialogRemove" 
      @close="showDialogRemove = false" 
      @remove="remove"
    >
    </removeModal>

    <!-- 
      Модальне вікно предперегляду оповіщення (детальне)
      - йде перевірка previewBox.show
      - @close - подія закриття модалки
      - :data - дані повідомлення
      - :lang - мова повідомлення
    -->
    <previewMoreMsg 
      v-if="this.previewBox.show"
      @close="this.previewBox.show = false"
      :data="this.previewBox.data"
      :lang="this.previewBox.lang"
    />

    <!-- 
      Модальне вікно предперегляду оповіщення (коротке)
      - йде перевірка previewBox.shortShow
      - @close - подія закриття модалки
      :data - дані повідомлення
      :type - тип повідомлення
    -->
    <previewMsg 
      v-if="previewBox.shortShow"
      @close="previewBox.shortShow = false"
      :data="this.previewBox.data"
      :type="this.previewBox.type"
    />

  </template>
  
<script>
  import removeModal from '@/components/modal/modalremove.vue' // Діалогове вікно підтвердження видалення
  import modal from '@/components/modal/modal-lg' // компонент модального вікна
  import createDesc from '../create/create-desc.vue'; // Модальне вікно створення детального повідомлення
  import previewMsg from '../view/preview/message' // Модальне вікно предперегляду повідомлення
  import previewMoreMsg from '../view/preview/message-desc' // Модальне вікно предперегляду повідомлення (детальне)
  import { mutateTypeMessage, mutateViewMessage, mutateRecipientMessage, mutateStatusMessage, mutatePermsDash } from '@/usabilityScripts/globalMutate.js'
  import { MessagesInSky } from '@/API.js'; // клас для взаємодії з API 
  import { Messages } from '@/API_Dash.js' // клас для взаємодії з API 
  import { copy } from '@/usabilityScripts/copy.js' // Функція копіювання
  import { storeS } from "@/store"; // глобальне сховище даних

  let apiMessagesSky = new MessagesInSky()
  let apiServe = new Messages()

  export default {
    props: ['obj'],
    components: {
      modal,
      createDesc,
      removeModal,
      previewMsg,
      previewMoreMsg
    },
    data() {
      return {
        showDialogRemove: false, // прапорець відображення вікна видалення картки
        showCreateDesc: false, // прапорець відображення вікна створення детальної інформації
        componentKey: 0,
        availableLangs: [ // Мови для перекладу
          {
            "value": 'fr',
            "title": "Français"
          },
          {
            "value": "en",
            "title": "English"
          },
          {
            "value": "ru",
            "title": "Русский"
          },
          {
            "value": "de",
            "title": "Deutsch"
          },
          {
            "value": "es",
            "title": "Español"
          },
          {
            "value": "pl",
            "title": "Polski"
          },
          {
            "value": "uk",
            "title": "Українська"
          },
          {
            "value": "it",
            "title": "Italiano"
          },
          {
            "value": "he",
            "title": "עִבְרִית"
          },
          {
            "value": "tr",
            "title": "Türkçe"
          },
          {
            "value": "et",
            "title": "eesti keel"
          },
          {
            "value": "be",
            "title": "Беларуская мова"
          },
          {
            "value": "ar",
            "title": "العربية"
          },
          {
            "value": "fi",
            "title": "suomi"
          },
          {
            "value": "lt",
            "title": "lietuvių kalba"
          },
          {
            "value": "tg",
            "title": "Забони тоҷикӣ"
          },
          {
            "value": "pt",
            "title": "Português"
          },
          {
            "value": "ka",
            "title": "Georgian (Georgia)"
          },
          {
            "value": "cn",
            "title": "Chinese Simplified"
          },
          {
            "value": "el",
            "title": "Greek"
          },
          {
            "value": "km",
            "title": "Khmer (Cambodia)"
          },
          {
            "value": "lv",
            "title": "Latvian"
          },
          {
            "value": "ro",
            "title": "Romanian"
          },
          {
            "value": "th",
            "title": "ภาษาไทย (Thai)"
          }
        ],
        previewBox: {
          data: '', // Дані/текст для відображення предперегляду
          lang: '', // Мова предперегляду
          type: '', // Тип сповіщення
          show: false, // Стан відображення вікна з детальною інформацією
          shortShow: false // Стан відображення вікна повідомлення
        },
      };
    },
    created() {
      this.form = this.obj
    },
    methods: {
      edit() {
        // Відправляє подію "edit" разом з поточною формою для редагування
        this.$emit('edit', this.form)
      },
      remove(){
        // Видаляємо повідомлення за його ідентифікатором через API

        apiMessagesSky.deleteMessage(this.form.id).then(result => {
          if(result.status == 'done') {
            this.$toast.success(this.$t('Removed'));
            this.$emit('close')
            this.$emit('getdata')
          } else {
            this.$toast.error(this.$t('err'))
          }
        })
      },
      confirm(id){
        // Підтверджує повідомлення за його ідентифікатором через API

        apiMessagesSky.confirmMessage(id).then(result => {
          if(result.status == 'done') {
            // Викликає метод для оновлення вмісту карточки
            this.updateCard()
          } else {
            this.$toast.error(this.$t('err'))
          }
        })
      },
      updateCard(){
        // Отримує оновлені дані повідомлення з API і оновлює відповідні поля форми

        apiMessagesSky.getMessage(this.form.id).then(result => {
          if(result.status == 'done') {
              this.form = result.data // Оновлює форму
              this.componentKey++ // Оновлює ключ компонента для перерендерингу
          } else {
            this.$toast.error(this.$t('err'))
          }
        })
      },
      send(form){
        // Відправляє нове повідомлення через API

        if(form.perms == ['all']) {
          form.perms = ''
        }
        delete form.status // Видаляє непотрібні поля
        delete form.datetime

        // Надсилаємо повідомлення через API
        apiServe.addMessage(form).then(result => {
          if(result.status == 'done') {
            this.$toast.success(this.$t('success'))
            this.$emit('close')
          } else {
            this.$toast.error(this.$t('err'))
          }
        })
      },
      copy(item){
        // Копіювання

        if(copy(item) == true) {
            this.$toast.success(this.$t('Copied'));
        } else {
            this.$toast.error(`Fucking mistake, contact Borys`);
        }
        return
      },
      previewShow(item, k, type){
        // Показує попередній перегляд вмісту на обраній мові та типі

        if(type == 'more'){
          this.previewBox.data = this.form.more_langs[item];
          this.previewBox.lang = this.availableLangs.find(el => item == el.value).title;
          this.previewBox.show = true
        } else {
          this.previewBox.data = this.form.langs[item];
          this.previewBox.type = this.form.type
          this.previewBox.lang = this.availableLangs.find(el => item == el.value).title;
          this.previewBox.shortShow = true
        }
      },
      mutateStatusMessage(e) {
        /*
          Функція для застосування перекладу статусу повідомлення
        */
       
        return mutateStatusMessage(e)
      },
      mutateTypeMessage(e) {
        /*
          Функція для застосування перекладу типу повідомлення
        */

        return mutateTypeMessage(e)
      },
      mutateViewMessage(e) {
        /*
          Функція для застосування перекладу видимості повідомлення
        */

        return mutateViewMessage(e)
      },
      mutateRecipientMessage(e) {
        /*
          Функція для застосування перекладу отримувача повідомлення
        */

        return mutateRecipientMessage(e)
      },
      mutatePerms(e){
        /*
          Функція для застосування перекладу до прав доступу
        */

        return mutatePermsDash(e)
      }
    },
    computed: {
      user() {
        /*
            Повертає дані за користувача з глобального стору.
        */
        return storeS.userbase
      },
      perms() {
        /*
            Властивість, яка повертає права доступу користувача зі збережених даних у глобальному стору.
        */
        return storeS.perms
      }
    }
  }
</script>
  
<style scoped>

</style>